import styled from 'styled-components'

import GearIcon from 'assets/svg/app/gear-icon.svg'
import SvgLogo from 'assets/svg/brand/logo-v3.svg'
import Spacer from 'components/Spacer'
import { Body } from 'components/Text'

export default function MaintenanceModal() {
	return (
		<Container>
			<Content>
				<GearIcon width="22px" />
				<Spacer height={14} />

				<Body color="primary" weight="bold" size="large">
					Down for Maintenance
				</Body>
				<Spacer height={10} />
				<Body color="secondary">
					We are currently working on a better trading experience for you, we’ll have you back to
					trading in no time. For more information follow{' '}
					<a href="https://x.com/Kwenta_io?s=20">Kwenta on X</a> or{' '}
					<a href="https://discord.gg/H7YmEJ7m">Discord</a>
				</Body>
				<Spacer height={120} />
				<SvgLogo />
			</Content>
		</Container>
	)
}

const Container = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`

const Content = styled.div`
	width: 300px;
	text-align: center;
`
