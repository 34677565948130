import { wei } from '@kwenta/wei';
import { ZERO_WEI } from '../constants/number';
import { DECAY_RATE, EPOCH_CONFIGS, INITIAL_WEEKLY_SUPPLY, STAKING_ENDPOINTS, STAKING_REWARDS_RATIO, SUPPLY_RATE, WEEK, } from '../constants/staking';
import { weiFromWei } from './number';
export function getEpochDetails(epoch, config = EPOCH_CONFIGS.default) {
    const currentEpochTime = config.epochStart + WEEK * epoch;
    const epochEndTime = currentEpochTime + WEEK;
    return { epochStart: currentEpochTime, epochEnd: epochEndTime };
}
export function getEpochPeriod(config = EPOCH_CONFIGS.default) {
    const timestamp = Math.floor(Date.now() / 1000);
    return Math.floor((timestamp - config.epochStart) / WEEK);
}
export function getApy(totalStakedBalance, weekCounter) {
    const startWeeklySupply = weiFromWei(INITIAL_WEEKLY_SUPPLY).mul(SUPPLY_RATE.pow(weekCounter));
    const yearlyRewards = startWeeklySupply.mul(wei(1).sub(SUPPLY_RATE.pow(52))).div(wei(DECAY_RATE));
    return wei(totalStakedBalance).gt(0)
        ? yearlyRewards.mul(wei(STAKING_REWARDS_RATIO)).div(wei(totalStakedBalance))
        : ZERO_WEI;
}
export const getStakingGqlEndpoint = (networkId) => {
    return STAKING_ENDPOINTS[networkId] || STAKING_ENDPOINTS[10];
};
