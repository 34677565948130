// Copied over from: https://github.com/Synthetixio/js-monorepo
export const TRANSACTION_EVENTS = [
    'txSent',
    'txConfirmed',
    'txFailed',
    'txError',
];
export const TRANSACTION_EVENTS_MAP = Object.fromEntries(TRANSACTION_EVENTS.map((event) => [event, event]));
export const DEFAULT_GAS_BUFFER = 5000;
export const ETH_UNIT = 1000000000000000000;
export const GWEI_DECIMALS = 9;
export const NODE_INTERFACE_ADDRESS = '0x00000000000000000000000000000000000000C8';
export const GAS_BUFFER = 120;
export const EST_TRADE_TX_COST_USDC = 0.5;
