import { cookieStorage, createConfig as createAlchemyConfig } from '@account-kit/core'
import {
	arbitrum,
	arbitrumSepolia,
	base,
	baseSepolia,
	mainnet,
	optimism,
	optimismSepolia,
	polygon,
} from '@account-kit/infra'

import { DEFAULT_CHAIN, DEFAULT_SESSION_MS } from 'constants/network'

export const alchemyConfig = createAlchemyConfig({
	chain: DEFAULT_CHAIN,
	apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY!,
	chains: [
		arbitrum,
		arbitrumSepolia,
		base,
		baseSepolia,
		mainnet,
		optimism,
		optimismSepolia,
		polygon,
	].map((chain) => ({
		chain,
		policyId: process.env.NEXT_PUBLIC_ALCHEMY_POLICY_ID!,
	})),
	ssr: true,
	storage: cookieStorage,
	sessionConfig: {
		expirationTimeMs: DEFAULT_SESSION_MS,
	},
})
