const abi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_vToken',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_token',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'redeemer',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'redeemedAmount',
                type: 'uint256',
            },
        ],
        name: 'Redeemed',
        type: 'event',
    },
    {
        inputs: [],
        name: 'redeem',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'token',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'vToken',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
export default abi;
