import KwentaSDK from '@kwenta/sdk'
import { SupportedNetworkIds } from '@kwenta/sdk/types'

import logError from 'utils/logError'

const sdk = new KwentaSDK({
	supportedChains: [
		SupportedNetworkIds.BASE_MAINNET,
		SupportedNetworkIds.BASE_SEPOLIA,
		SupportedNetworkIds.OPTIMISM_MAINNET,
		SupportedNetworkIds.OPTIMISM_SEPOLIA,
		SupportedNetworkIds.ETHEREUM_MAINNET,
		SupportedNetworkIds.ARB_MAINNET,
		SupportedNetworkIds.ARB_SEPOLIA,
	],
	logError,
})

export default sdk
