const abi = [
    {
        inputs: [
            { internalType: 'address', name: '_usdc', type: 'address' },
            { internalType: 'address', name: '_usdx', type: 'address' },
            { internalType: 'address', name: '_spotMarket', type: 'address' },
            { internalType: 'address', name: '_perpsMarket', type: 'address' },
            { internalType: 'address', name: '_referrer', type: 'address' },
            { internalType: 'uint128', name: '_susdcSpotId', type: 'uint128' },
            { internalType: 'address', name: '_aave', type: 'address' },
            { internalType: 'address', name: '_router', type: 'address' },
            { internalType: 'address', name: '_quoter', type: 'address' },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        inputs: [{ internalType: 'string', name: 'reason', type: 'string' }],
        name: 'BuyFailed',
        type: 'error',
    },
    { inputs: [], name: 'NotPermitted', type: 'error' },
    {
        inputs: [{ internalType: 'address', name: 'caller', type: 'address' }],
        name: 'OnlyAave',
        type: 'error',
    },
    { inputs: [], name: 'OnlyPlumber', type: 'error' },
    {
        inputs: [{ internalType: 'bytes', name: 'reason', type: 'bytes' }],
        name: 'PullFailed',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'bytes', name: 'reason', type: 'bytes' }],
        name: 'PushFailed',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'enum Reentrancy.Stage', name: 'actual', type: 'uint8' },
            { internalType: 'enum Reentrancy.Stage', name: 'expected', type: 'uint8' },
        ],
        name: 'ReentrancyDetected',
        type: 'error',
    },
    { inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' },
    {
        inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
        name: 'SafeERC20FailedOperation',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'string', name: 'reason', type: 'string' }],
        name: 'SellFailed',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'string', name: 'reason', type: 'string' }],
        name: 'SwapFailed',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'string', name: 'reason', type: 'string' }],
        name: 'UnwrapFailed',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'string', name: 'reason', type: 'string' }],
        name: 'WrapFailed',
        type: 'error',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: 'plumber', type: 'address' }],
        name: 'PlumberDesignated',
        type: 'event',
    },
    {
        inputs: [],
        name: 'AAVE',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'BURN_PERMISSION',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'FEE_TIER',
        outputs: [{ internalType: 'uint24', name: '', type: 'uint24' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'MODIFY_PERMISSION',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'PERPS_MARKET',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'PLUMBER',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'QUOTER',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'REFERRAL_CODE',
        outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'REFERRER',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'ROUTER',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'SPOT_MARKET',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'SUSDC_SPOT_ID',
        outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'USDC',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'USDX',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'USDX_ID',
        outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
        ],
        name: 'burn',
        outputs: [{ internalType: 'uint256', name: 'excess', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_synthId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountOut', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'buy',
        outputs: [
            { internalType: 'uint256', name: 'received', type: 'uint256' },
            { internalType: 'address', name: 'synth', type: 'address' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_newPlumber', type: 'address' }],
        name: 'designatePlumber',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint256', name: '_flashloan', type: 'uint256' },
            { internalType: 'uint256', name: '_premium', type: 'uint256' },
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'bytes', name: '_params', type: 'bytes' },
        ],
        name: 'executeOperation',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
        name: 'flush',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes', name: '_path', type: 'bytes' },
            { internalType: 'uint256', name: '_amountOut', type: 'uint256' },
        ],
        name: 'quoteSwapFor',
        outputs: [
            { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
            { internalType: 'uint160[]', name: 'sqrtPriceX96AfterList', type: 'uint160[]' },
            { internalType: 'uint32[]', name: 'initializedTicksCrossedList', type: 'uint32[]' },
            { internalType: 'uint256', name: 'gasEstimate', type: 'uint256' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes', name: '_path', type: 'bytes' },
            { internalType: 'uint256', name: '_amountIn', type: 'uint256' },
        ],
        name: 'quoteSwapWith',
        outputs: [
            { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
            { internalType: 'uint160[]', name: 'sqrtPriceX96AfterList', type: 'uint160[]' },
            { internalType: 'uint32[]', name: 'initializedTicksCrossedList', type: 'uint32[]' },
            { internalType: 'uint256', name: 'gasEstimate', type: 'uint256' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_synthId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountOut', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'sell',
        outputs: [{ internalType: 'uint256', name: 'received', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_from', type: 'address' },
            { internalType: 'bytes', name: '_path', type: 'bytes' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_maxAmountIn', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'swapFor',
        outputs: [{ internalType: 'uint256', name: 'deducted', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_from', type: 'address' },
            { internalType: 'bytes', name: '_path', type: 'bytes' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_amountOutMinimum', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'swapWith',
        outputs: [{ internalType: 'uint256', name: 'received', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint128', name: '_collateralId', type: 'uint128' },
            { internalType: 'uint256', name: '_collateralAmount', type: 'uint256' },
            { internalType: 'address', name: '_collateral', type: 'address' },
            { internalType: 'bytes', name: '_path', type: 'bytes' },
            { internalType: 'uint256', name: '_zapMinAmountOut', type: 'uint256' },
            { internalType: 'uint256', name: '_unwrapMinAmountOut', type: 'uint256' },
            { internalType: 'uint256', name: '_swapMaxAmountIn', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'unwind',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_token', type: 'address' },
            { internalType: 'uint128', name: '_synthId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountOut', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'unwrap',
        outputs: [{ internalType: 'uint256', name: 'unwrapped', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_synthId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_token', type: 'address' },
            { internalType: 'uint128', name: '_synthId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountOut', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'wrap',
        outputs: [{ internalType: 'uint256', name: 'wrapped', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountOut', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'zapIn',
        outputs: [{ internalType: 'uint256', name: 'zapped', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountOut', type: 'uint256' },
            { internalType: 'address', name: '_receiver', type: 'address' },
        ],
        name: 'zapOut',
        outputs: [{ internalType: 'uint256', name: 'zapped', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export default abi;
