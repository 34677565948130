import { UNSUPPORTED_NETWORK } from '../constants/errors';
import { fleekClient } from '../utils/files';
import { StatusMap } from '../utils/system';
export default class SystemService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async getSynthetixStatus(chainId) {
        const contracts = this.sdk.context.contractConfigs[chainId]?.common;
        if (!contracts?.SystemStatus || !contracts?.DappMaintenance) {
            throw new Error(UNSUPPORTED_NETWORK);
        }
        const { SystemStatus, DappMaintenance } = contracts;
        const [isSystemUpgrading, isExchangePaused] = await this.sdk.context
            .publicClient(chainId)
            .multicall({
            allowFailure: false,
            contracts: [
                {
                    ...SystemStatus,
                    functionName: 'isSystemUpgrading',
                },
                {
                    ...DappMaintenance,
                    functionName: 'isPausedSX',
                },
            ],
        });
        return isSystemUpgrading || isExchangePaused;
    }
    async getKwentaStatus() {
        const response = await fleekClient.get('kwenta-status.json', {
            headers: { 'Cache-Control': 'no-cache' },
        });
        return {
            ...response.data,
            status: StatusMap[response.data.status],
        };
    }
}
