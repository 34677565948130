const MARGIN_ENGINE_ABI = [
    {
        inputs: [
            { internalType: 'address', name: '_perpsMarketProxy', type: 'address' },
            { internalType: 'address', name: '_spotMarketProxy', type: 'address' },
            { internalType: 'address', name: '_sUSDProxy', type: 'address' },
            { internalType: 'address', name: '_pDAO', type: 'address' },
            { internalType: 'address', name: '_usdc', type: 'address' },
            { internalType: 'uint128', name: '_sUSDCId', type: 'uint128' },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        inputs: [{ internalType: 'address', name: 'target', type: 'address' }],
        name: 'AddressEmptyCode',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'address', name: 'token', type: 'address' },
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'ApprovalFailed',
        type: 'error',
    },
    { inputs: [], name: 'CannotExecuteOrder', type: 'error' },
    {
        inputs: [{ internalType: 'address', name: 'implementation', type: 'address' }],
        name: 'ERC1967InvalidImplementation',
        type: 'error',
    },
    { inputs: [], name: 'ERC1967NonPayable', type: 'error' },
    { inputs: [], name: 'FailedInnerCall', type: 'error' },
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'InsufficientAmount',
        type: 'error',
    },
    { inputs: [], name: 'InsufficientCredit', type: 'error' },
    {
        inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
        name: 'InvalidConditionSelector',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'uint128', name: 'id', type: 'uint128' }],
        name: 'InvalidIdSUSDC',
        type: 'error',
    },
    { inputs: [], name: 'InvalidNonce', type: 'error' },
    { inputs: [], name: 'MaxConditionSizeExceeded', type: 'error' },
    { inputs: [], name: 'NonUpgradeable', type: 'error' },
    { inputs: [], name: 'NotSupported', type: 'error' },
    { inputs: [], name: 'OnlyPDAO', type: 'error' },
    { inputs: [], name: 'SUSDZeroAddress', type: 'error' },
    { inputs: [], name: 'SpotMarketZeroAddress', type: 'error' },
    {
        inputs: [
            { internalType: 'address', name: 'token', type: 'address' },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'TransferFailed',
        type: 'error',
    },
    { inputs: [], name: 'USDCZeroAddress', type: 'error' },
    { inputs: [], name: 'UUPSUnauthorizedCallContext', type: 'error' },
    {
        inputs: [{ internalType: 'bytes32', name: 'slot', type: 'bytes32' }],
        name: 'UUPSUnsupportedProxiableUUID',
        type: 'error',
    },
    { inputs: [], name: 'Unauthorized', type: 'error' },
    { inputs: [], name: 'ZeroAddress', type: 'error' },
    {
        anonymous: false,
        inputs: [
            {
                components: [
                    { internalType: 'uint256', name: 'settlementTime', type: 'uint256' },
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IPerpsMarketProxy.OrderCommitmentRequest',
                        name: 'request',
                        type: 'tuple',
                    },
                ],
                indexed: false,
                internalType: 'struct IPerpsMarketProxy.Data',
                name: 'order',
                type: 'tuple',
            },
            { indexed: false, internalType: 'uint256', name: 'synthetixFees', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'executorFee', type: 'uint256' },
        ],
        name: 'ConditionalOrderExecuted',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'uint128', name: 'accountId', type: 'uint128' },
            { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'Credited',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'uint128', name: 'accountId', type: 'uint128' },
            { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'Debited',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'uint128', name: 'accountId', type: 'uint128' },
            { indexed: false, internalType: 'uint256', name: 'word', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'mask', type: 'uint256' },
        ],
        name: 'UnorderedNonceInvalidation',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: true, internalType: 'address', name: 'implementation', type: 'address' }],
        name: 'Upgraded',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'uint256', name: 'amountWrapped', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'amountMinted', type: 'uint256' },
        ],
        name: 'ZappedIn',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'uint256', name: 'amountBurned', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'amountUnwrapped', type: 'uint256' },
        ],
        name: 'ZappedOut',
        type: 'event',
    },
    {
        inputs: [],
        name: 'DOMAIN_SEPARATOR',
        outputs: [{ internalType: 'bytes32', name: 'separator', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'UPGRADE_INTERFACE_VERSION',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bool', name: 'isReduceOnly', type: 'bool' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IEngine.OrderDetails',
                        name: 'orderDetails',
                        type: 'tuple',
                    },
                    { internalType: 'address', name: 'signer', type: 'address' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bool', name: 'requireVerified', type: 'bool' },
                    { internalType: 'address', name: 'trustedExecutor', type: 'address' },
                    { internalType: 'uint256', name: 'maxExecutorFee', type: 'uint256' },
                    { internalType: 'bytes[]', name: 'conditions', type: 'bytes[]' },
                ],
                internalType: 'struct IEngine.ConditionalOrder',
                name: '_co',
                type: 'tuple',
            },
            { internalType: 'bytes', name: '_signature', type: 'bytes' },
            { internalType: 'uint256', name: '_fee', type: 'uint256' },
        ],
        name: 'canExecute',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_perpsMarketId', type: 'uint128' },
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'int128', name: '_sizeDelta', type: 'int128' },
            { internalType: 'uint128', name: '_settlementStrategyId', type: 'uint128' },
            { internalType: 'uint256', name: '_acceptablePrice', type: 'uint256' },
            { internalType: 'bytes32', name: '_trackingCode', type: 'bytes32' },
            { internalType: 'address', name: '_referrer', type: 'address' },
        ],
        name: 'commitOrder',
        outputs: [
            {
                components: [
                    { internalType: 'uint256', name: 'settlementTime', type: 'uint256' },
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IPerpsMarketProxy.OrderCommitmentRequest',
                        name: 'request',
                        type: 'tuple',
                    },
                ],
                internalType: 'struct IPerpsMarketProxy.Data',
                name: 'retOrder',
                type: 'tuple',
            },
            { internalType: 'uint256', name: 'fees', type: 'uint256' },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint128', name: 'accountId', type: 'uint128' }],
        name: 'credit',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
        ],
        name: 'creditAccount',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
        ],
        name: 'creditAccountZap',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
        ],
        name: 'debitAccount',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
        ],
        name: 'debitAccountZap',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'eip712Domain',
        outputs: [
            { internalType: 'bytes1', name: 'fields', type: 'bytes1' },
            { internalType: 'string', name: 'name', type: 'string' },
            { internalType: 'string', name: 'version', type: 'string' },
            { internalType: 'uint256', name: 'chainId', type: 'uint256' },
            { internalType: 'address', name: 'verifyingContract', type: 'address' },
            { internalType: 'bytes32', name: 'salt', type: 'bytes32' },
            { internalType: 'uint256[]', name: 'extensions', type: 'uint256[]' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bool', name: 'isReduceOnly', type: 'bool' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IEngine.OrderDetails',
                        name: 'orderDetails',
                        type: 'tuple',
                    },
                    { internalType: 'address', name: 'signer', type: 'address' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bool', name: 'requireVerified', type: 'bool' },
                    { internalType: 'address', name: 'trustedExecutor', type: 'address' },
                    { internalType: 'uint256', name: 'maxExecutorFee', type: 'uint256' },
                    { internalType: 'bytes[]', name: 'conditions', type: 'bytes[]' },
                ],
                internalType: 'struct IEngine.ConditionalOrder',
                name: '_co',
                type: 'tuple',
            },
            { internalType: 'bytes', name: '_signature', type: 'bytes' },
            { internalType: 'uint256', name: '_fee', type: 'uint256' },
        ],
        name: 'execute',
        outputs: [
            {
                components: [
                    { internalType: 'uint256', name: 'settlementTime', type: 'uint256' },
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IPerpsMarketProxy.OrderCommitmentRequest',
                        name: 'request',
                        type: 'tuple',
                    },
                ],
                internalType: 'struct IPerpsMarketProxy.Data',
                name: 'retOrder',
                type: 'tuple',
            },
            { internalType: 'uint256', name: 'synthetixFees', type: 'uint256' },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address payable', name: 'EIP7412Implementer', type: 'address' },
            { internalType: 'bytes', name: 'signedOffchainData', type: 'bytes' },
        ],
        name: 'fulfillOracleQuery',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint256', name: '_nonce', type: 'uint256' },
        ],
        name: 'hasUnorderedNonceBeenUsed',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint256', name: '_wordPos', type: 'uint256' },
            { internalType: 'uint256', name: '_mask', type: 'uint256' },
        ],
        name: 'invalidateUnorderedNonces',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'address', name: '_caller', type: 'address' },
        ],
        name: 'isAccountDelegate',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'address', name: '_caller', type: 'address' },
        ],
        name: 'isAccountOwner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint128', name: '_marketId', type: 'uint128' }],
        name: 'isMarketOpen',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_marketId', type: 'uint128' },
            { internalType: 'int128', name: '_sizeDelta', type: 'int128' },
            { internalType: 'uint256', name: '_fee', type: 'uint256' },
        ],
        name: 'isOrderFeeBelow',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint128', name: '_marketId', type: 'uint128' },
            { internalType: 'int128', name: '_size', type: 'int128' },
        ],
        name: 'isPositionSizeAbove',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint128', name: '_marketId', type: 'uint128' },
            { internalType: 'int128', name: '_size', type: 'int128' },
        ],
        name: 'isPositionSizeBelow',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_marketId', type: 'uint128' },
            { internalType: 'uint256', name: '_price', type: 'uint256' },
            { internalType: 'int128', name: '_size', type: 'int128' },
        ],
        name: 'isPriceAbove',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_marketId', type: 'uint128' },
            { internalType: 'uint256', name: '_price', type: 'uint256' },
            { internalType: 'int128', name: '_size', type: 'int128' },
        ],
        name: 'isPriceBelow',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_timestamp', type: 'uint256' }],
        name: 'isTimestampAfter',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_timestamp', type: 'uint256' }],
        name: 'isTimestampBefore',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'uint128', name: '_synthMarketId', type: 'uint128' },
            { internalType: 'int256', name: '_amount', type: 'int256' },
        ],
        name: 'modifyCollateral',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: '_accountId', type: 'uint128' },
            { internalType: 'int256', name: '_amount', type: 'int256' },
        ],
        name: 'modifyCollateralZap',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes[]', name: 'data', type: 'bytes[]' }],
        name: 'multicall',
        outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
            { internalType: 'uint256', name: 'index', type: 'uint256' },
        ],
        name: 'nonceBitmap',
        outputs: [{ internalType: 'uint256', name: 'bitmap', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'proxiableUUID',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'newImplementation', type: 'address' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'upgradeToAndCall',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bool', name: 'isReduceOnly', type: 'bool' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IEngine.OrderDetails',
                        name: 'orderDetails',
                        type: 'tuple',
                    },
                    { internalType: 'address', name: 'signer', type: 'address' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bool', name: 'requireVerified', type: 'bool' },
                    { internalType: 'address', name: 'trustedExecutor', type: 'address' },
                    { internalType: 'uint256', name: 'maxExecutorFee', type: 'uint256' },
                    { internalType: 'bytes[]', name: 'conditions', type: 'bytes[]' },
                ],
                internalType: 'struct IEngine.ConditionalOrder',
                name: '_co',
                type: 'tuple',
            },
        ],
        name: 'verifyConditions',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bool', name: 'isReduceOnly', type: 'bool' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IEngine.OrderDetails',
                        name: 'orderDetails',
                        type: 'tuple',
                    },
                    { internalType: 'address', name: 'signer', type: 'address' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bool', name: 'requireVerified', type: 'bool' },
                    { internalType: 'address', name: 'trustedExecutor', type: 'address' },
                    { internalType: 'uint256', name: 'maxExecutorFee', type: 'uint256' },
                    { internalType: 'bytes[]', name: 'conditions', type: 'bytes[]' },
                ],
                internalType: 'struct IEngine.ConditionalOrder',
                name: '_co',
                type: 'tuple',
            },
            { internalType: 'bytes', name: '_signature', type: 'bytes' },
        ],
        name: 'verifySignature',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        components: [
                            { internalType: 'uint128', name: 'marketId', type: 'uint128' },
                            { internalType: 'uint128', name: 'accountId', type: 'uint128' },
                            { internalType: 'int128', name: 'sizeDelta', type: 'int128' },
                            { internalType: 'uint128', name: 'settlementStrategyId', type: 'uint128' },
                            { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
                            { internalType: 'bool', name: 'isReduceOnly', type: 'bool' },
                            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
                            { internalType: 'address', name: 'referrer', type: 'address' },
                        ],
                        internalType: 'struct IEngine.OrderDetails',
                        name: 'orderDetails',
                        type: 'tuple',
                    },
                    { internalType: 'address', name: 'signer', type: 'address' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bool', name: 'requireVerified', type: 'bool' },
                    { internalType: 'address', name: 'trustedExecutor', type: 'address' },
                    { internalType: 'uint256', name: 'maxExecutorFee', type: 'uint256' },
                    { internalType: 'bytes[]', name: 'conditions', type: 'bytes[]' },
                ],
                internalType: 'struct IEngine.ConditionalOrder',
                name: '_co',
                type: 'tuple',
            },
        ],
        name: 'verifySigner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
];
export default MARGIN_ENGINE_ABI;
