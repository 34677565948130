import request, { gql } from 'graphql-request';
export const queryOperatorsByOwner = async (sdk, walletAddress) => {
    if (!walletAddress)
        return [];
    const response = await request(sdk.kwentaToken.stakingGqlEndpoint, gql `
			query operatorApproveds($walletAddress: String!) {
				operatorApproveds(
					orderBy: blockTimestamp
					orderDirection: desc
					where: { owner: $walletAddress }
				) {
					operator
					blockTimestamp
					approved
				}
			}
		`, { walletAddress });
    return response?.operatorApproveds || [];
};
