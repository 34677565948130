import { wei } from '@kwenta/wei';
import { Big6Math, ChainMarkets, OrderTypes, PositionSide as PerennialPositionSide, SupportedAsset, addressToAsset, calcMaxLeverage, } from '@perennial/sdk';
import { getAddress, parseUnits } from 'viem';
import { KWENTA_REFERRER_ARB, SL_TP_SIZE_PERENNIAL } from '../constants';
import { PerpsProvider } from '../types';
import { FuturesMarginType, FuturesMarketAsset, OrderTypeEnum, PositionSide, PotentialTradeStatus, } from '../types/futures';
import { MarketKeyByAsset, getDisplayAsset } from './futures';
import { formatCurrency } from './number';
export const assetToFuturesMarketAsset = (asset) => {
    if (asset === SupportedAsset.btc || asset === SupportedAsset.eth) {
        return `s${asset.toUpperCase()}`;
    }
    switch (asset) {
        case SupportedAsset.doge:
            return FuturesMarketAsset.DOGE10;
        case SupportedAsset.mog:
            return FuturesMarketAsset.MOG1M;
        case SupportedAsset.jpy:
            return FuturesMarketAsset.JPY1K;
    }
    return asset.toUpperCase();
};
export const futuresMarketAssetToAsset = (asset) => {
    if (asset === FuturesMarketAsset.sBTC || asset === FuturesMarketAsset.sETH) {
        return asset.toLowerCase().substring(1, asset.length);
    }
    switch (asset) {
        case FuturesMarketAsset.DOGE10:
            return SupportedAsset.doge;
        case FuturesMarketAsset.MOG1M:
            return SupportedAsset.mog;
        case FuturesMarketAsset.JPY1K:
            return SupportedAsset.jpy;
    }
    return asset.toLocaleLowerCase();
};
export const assetToMarketKey = (asset) => {
    const marketAsset = assetToFuturesMarketAsset(asset);
    return MarketKeyByAsset[marketAsset];
};
export const getSide = (side, sizeDelta) => {
    if (side === PerennialPositionSide.none || side === PerennialPositionSide.maker) {
        return PositionSide.LONG;
    }
    if (!sizeDelta)
        return side === PerennialPositionSide.long ? PositionSide.LONG : PositionSide.SHORT;
    return (side === PerennialPositionSide.short && Number(sizeDelta) < 0) ||
        (side === PerennialPositionSide.long && Number(sizeDelta) > 0)
        ? PositionSide.LONG
        : PositionSide.SHORT;
};
export const maybeDeriveSupportedAsset = (asset) => {
    const assetName = getDisplayAsset(asset);
    if (assetName) {
        switch (assetName) {
            case FuturesMarketAsset.DOGE10:
                return SupportedAsset.doge;
            case FuturesMarketAsset.MOG1M:
                return SupportedAsset.mog;
            case FuturesMarketAsset.JPY1K:
                return SupportedAsset.jpy;
        }
        const asSupportedAsset = assetName.toLowerCase();
        if (Object.values(SupportedAsset).includes(asSupportedAsset)) {
            return asSupportedAsset;
        }
    }
    return;
};
export const getOrderTypeFromOpenOrder = (order) => {
    if (Number(order.triggerOrderDelta) > 0) {
        return OrderTypeEnum.LIMIT;
    }
    // Longs
    if (order.triggerOrderSide === 1) {
        return order.triggerOrderComparison === 1 ? OrderTypeEnum.TAKE_PROFIT : OrderTypeEnum.STOP_LOSS;
    }
    // Shorts
    return order.triggerOrderComparison === 1 ? OrderTypeEnum.STOP_LOSS : OrderTypeEnum.TAKE_PROFIT;
};
export const getConditionalOrderParam = (orderType, value) => {
    switch (orderType) {
        case OrderTypes.limit:
            return { limitPrice: value };
        case OrderTypes.stopLoss:
            return { stopLossPrice: value };
        case OrderTypes.takeProfit:
            return { takeProfitPrice: value };
    }
};
export const openOrderToConditionalOrder = (openOrder) => {
    const size = toWei6(BigInt(openOrder.triggerOrderDelta));
    const asset = assetToFuturesMarketAsset(openOrder.market);
    return {
        id: Number(openOrder.nonce),
        account: getAddress(openOrder.account),
        asset: asset,
        market: getAddress(openOrder.marketAddress),
        marketKey: assetToMarketKey(openOrder.market),
        size: size,
        targetPrice: toWei6(BigInt(openOrder.triggerOrderPrice)),
        desiredFillPrice: toWei6(BigInt(openOrder.triggerOrderPrice)),
        reduceOnly: BigInt(openOrder.triggerOrderDelta) <= BigInt(0),
        marginDelta: toWei6(BigInt(openOrder.associatedOrder?.depositTotal ?? '0')),
        orderType: getOrderTypeFromOpenOrder(openOrder),
        orderTypeDisplay: getOrderTypeFromOpenOrder(openOrder),
        sizeTxt: size.abs().eq(SL_TP_SIZE_PERENNIAL)
            ? 'Close'
            : formatCurrency(asset, size, {
                currencyKey: asset,
                suggestDecimals: true,
            }),
        targetPriceTxt: Big6Math.toFloatString(BigInt(openOrder.triggerOrderPrice)),
        side: openOrder.triggerOrderSide === 1 ? PositionSide.LONG : PositionSide.SHORT,
        isStale: false,
        isSlTp: toWei6(BigInt(openOrder.triggerOrderDelta)).eq(SL_TP_SIZE_PERENNIAL),
    };
};
export const getStatusCode = ({ orderExceedsLiquidity, notPermitted, maxLeveragedExceeded, insufficientMargin, }) => {
    if (orderExceedsLiquidity) {
        return PotentialTradeStatus.MAX_MARKET_SIZE_EXCEEDED;
    }
    if (notPermitted) {
        return PotentialTradeStatus.NOT_PERMITTED;
    }
    if (maxLeveragedExceeded) {
        return PotentialTradeStatus.MAX_LEVERAGE_EXCEEDED;
    }
    if (insufficientMargin) {
        return PotentialTradeStatus.INSUFFICIENT_MARGIN;
    }
    return PotentialTradeStatus.OK;
};
export const getPriceForTradePreview = ({ latestPrice, orderPrice, side, }) => {
    if (!orderPrice) {
        return latestPrice;
    }
    if (side === PositionSide.LONG) {
        return orderPrice > latestPrice ? latestPrice : orderPrice;
    }
    return orderPrice < latestPrice ? latestPrice : orderPrice;
};
export const mapMarkets = (chainId) => Object.keys(ChainMarkets[chainId]).map((key) => {
    const marketAddress = ChainMarkets[chainId][key];
    return {
        asset: key,
        marketAddress,
    };
});
export const toWei6 = (int) => {
    return wei(int, 6);
};
export const fromWei6 = (value) => {
    return parseUnits(value.toString(), 6);
};
export const orderCreatedToTrade = (orderCreateds, chainId) => {
    return orderCreateds.reduce((acc, o) => {
        const asset = addressToAsset(chainId, getAddress(o.market.id));
        if (asset) {
            acc.push({
                id: o.id,
                asset: assetToFuturesMarketAsset(asset),
                txnHash: o.transactionHashes[0],
                marketId: o.market.id,
                account: o.account.id,
                timestamp: Number(o.timestamp),
                fillPrice: wei(o.executionPrice).div(1e6),
                sizeDelta: wei(o.long).add(wei(o.short).neg()).div(1e6),
                interestCharged: wei(0),
                totalFees: wei(0),
                fundingAccrued: wei(0),
                side: wei(o.long).gt(0) || wei(o.short).lt(0) ? PositionSide.LONG : PositionSide.SHORT,
                pnl: wei(0),
                pnlWithFeesPaid: wei(0),
                ownerAddress: o.account.id,
                // TODO: check this
                settlementTxHash: o.transactionHashes[1],
                orderType: Number(o.maker) > 0 ? OrderTypeEnum.LIMIT : OrderTypeEnum.MARKET,
            });
        }
        return acc;
    }, []);
};
export const formatPerennialPositionHistory = (walletAddress, positionHistory) => {
    return positionHistory.map((position) => {
        const asset = assetToFuturesMarketAsset(position.market);
        const startNotional = toWei6(position.startSize).mul(toWei6(position.startPrice));
        const percentDenominator = position.startCollateral +
            (position.netDeposits > BigInt(0) ? position.netDeposits : BigInt(0));
        const rPnl = {
            pnl: toWei6(position.pnlAccumulations.pnl).add(position.pnlAccumulations.offset),
            netPnl: toWei6(position.netPnl),
            netPnlPct: toWei6(position.netPnl).div(toWei6(percentDenominator)),
        };
        const formattedPosition = {
            provider: PerpsProvider.PERENNIAL_V2_ARB,
            account: walletAddress,
            asset,
            marketAddress: position?.marketAddress,
            details: {
                id: position.positionId.toString(),
                side: position.side === PerennialPositionSide.long ? PositionSide.LONG : PositionSide.SHORT,
                size: toWei6(position.startSize),
                status: (position.liquidation ? 'liquidated' : 'closed'),
                accountType: FuturesMarginType.ISOLATED_MARGIN,
                timestamp: Number(position.startVersion) * 1000,
                openTimestamp: Number(position.startVersion) * 1000,
                closeTimestamp: position.endVersion ? Number(position.endVersion) * 1000 : undefined,
                stats: {
                    totalVolume: toWei6(position.totalNotional),
                    trades: Number(position.trades),
                    totalDeposits: toWei6(position.netDeposits),
                    netTransfers: toWei6(BigInt(0)), // TODO: Perennial pos net transfers
                },
                margin: {
                    initialMargin: toWei6(position.startCollateral),
                    leverage: startNotional.div(toWei6(position.startCollateral)),
                    remainingMargin: wei(0),
                    accessibleMargin: wei(0),
                    notionalValue: wei(0),
                    marginRatio: wei(0),
                    initialLeverage: wei(0),
                },
                price: {
                    entryPrice: toWei6(position.startPrice),
                    avgEntryPrice: toWei6(position.averageEntryPrice),
                    exitPrice: toWei6(position.averageExitPrice),
                    liquidationPrice: undefined,
                    lastPrice: undefined,
                },
                pnl: {
                    rPnl,
                    totalPnl: rPnl,
                },
                liquidation: {
                    isLiquidated: position.liquidation,
                },
                fees: {
                    owedInterest: toWei6(position.pnlAccumulations.interest),
                    feesPaid: toWei6(position.totalFees),
                    netFunding: toWei6(position.pnlAccumulations.funding),
                    accruedFunding: toWei6(position.pnlAccumulations.funding),
                    keeperFeesPaid: toWei6(position.feeAccumulations.settlement),
                    liquidationFee: toWei6(position.liquidationFee),
                },
                version: Number(position.startVersion), //TODO: [REFACTOR] Do we need this?
            },
        };
        return formattedPosition;
    });
};
export const perennialSdkTradeToFuturesTrade = (trade, account, positionId) => {
    return {
        id: trade.orderId.toString(),
        account: account,
        sizeDelta: toWei6(trade.delta ?? BigInt(0)),
        asset: assetToFuturesMarketAsset(trade.market),
        fillPrice: toWei6(trade.executionPrice),
        txnHash: trade.transactionHashes[0],
        timestamp: Number(trade.version),
        positionId: positionId,
        positionClosed: !trade.valid,
        side: getSide(trade.side, trade.delta),
        pnl: toWei6(trade.pnlAccumulations.pnl),
        totalFees: toWei6(trade.totalFees).sub(toWei6(trade.pnlAccumulations.offset)),
        pnlWithFeesPaid: toWei6(trade.netPnl),
        fundingAccrued: toWei6(trade.pnlAccumulations.funding),
        interestCharged: toWei6(trade.pnlAccumulations.interest),
        ownerAddress: account,
        marketId: trade.market,
        orderType: trade.liquidation ? 'Liquidation' : 'Market',
    };
};
export const formatPerennialOrderType = (orderType) => {
    switch (orderType) {
        case OrderTypeEnum.LIMIT:
            return OrderTypes.limit;
        case OrderTypeEnum.STOP:
        case OrderTypeEnum.STOP_LOSS:
            return OrderTypes.stopLoss;
        case OrderTypeEnum.TAKE_PROFIT:
            return OrderTypes.takeProfit;
        case OrderTypeEnum.MARKET:
            return OrderTypes.market;
    }
};
export const interfaceFee = (oneClickTrade, amount) => {
    if (oneClickTrade && !amount) {
        throw new Error('Amount is required for one click trade');
    }
    return {
        unwrap: true,
        receiver: KWENTA_REFERRER_ARB,
        amount: oneClickTrade ? parseUnits(amount, 6) : BigInt(0),
    };
};
export const getConditionalOrderFee = (orderType, oneClickTrade, amount) => {
    if (!oneClickTrade) {
        return {};
    }
    switch (orderType) {
        case OrderTypes.limit:
            return { limitOrderFees: { interfaceFee: interfaceFee(oneClickTrade, amount) } };
        case OrderTypes.stopLoss:
            return { stopLossFees: { interfaceFee: interfaceFee(oneClickTrade, amount) } };
        case OrderTypes.takeProfit:
            return { takeProfitFees: { interfaceFee: interfaceFee(oneClickTrade, amount) } };
    }
};
export const calcPerennialMaxLeverage = ({ margin, minMargin, collateral, }) => {
    if (!margin || !minMargin || !collateral) {
        return wei(0);
    }
    return toWei6(calcMaxLeverage({
        margin: fromWei6(margin),
        minMargin: fromWei6(minMargin),
        collateral: fromWei6(collateral),
    }));
};
