import type { PerpsProvider } from '@kwenta/sdk/types'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { FetchStatus } from 'state/types'

import type { FeeReimbursed, VipData, VipInitialState, VipTradeHistoryTableFilter } from './types'

export const VIP_INITIAL_STATE: VipInitialState = {
	queryStatuses: {},
	accumulatedVolume: '0',
	lastClaimedAtBlock: null,
	lastClaimedAt: null,
	lastFeeRebateAccumulatedStartBlock: null,
	tier: 0,
	totalFeeRebate: '0',
	paidFeesSinceClaimed: '0',
	thirtyDayVolume: '0',
	allTimeRebates: '0',
	claimPeriod: {
		startBlockNumber: 0,
		endBlockNumber: 0,
	},
	feeReimbursed: [],
	vipTradeHistoryTableFilter: null,
}

const vip = createSlice({
	name: 'vip',
	initialState: VIP_INITIAL_STATE,
	reducers: {
		setQueryStatus: (
			state,
			{
				payload,
			}: PayloadAction<{
				key: string
				provider?: PerpsProvider
				status: FetchStatus
				error?: string
			}>
		) => {
			state.queryStatuses[payload.key] = {
				provider: payload.provider,
				status: payload.status,
				error: payload.error,
			}
		},
		setVipData: (state, action: PayloadAction<VipData | null>) => {
			let vipData = action.payload
			if (!vipData) {
				vipData = VIP_INITIAL_STATE
			}
			state.totalFeeRebate = vipData.totalFeeRebate
			state.lastClaimedAtBlock = vipData.lastClaimedAtBlock
			state.lastClaimedAt = vipData.lastClaimedAt
			state.allTimeRebates = vipData.allTimeRebates
			state.lastFeeRebateAccumulatedStartBlock = vipData.lastFeeRebateAccumulatedStartBlock
		},
		setThirtyDayVolume: (state, action: PayloadAction<string>) => {
			state.thirtyDayVolume = action.payload
		},
		setAccountClaimPeriod: (
			state,
			action: PayloadAction<{ startBlockNumber: number; endBlockNumber: number }>
		) => {
			state.claimPeriod = {
				startBlockNumber: action.payload.startBlockNumber,
				endBlockNumber: action.payload.endBlockNumber,
			}
		},
		setFeeReimbursed: (state, action: PayloadAction<FeeReimbursed[]>) => {
			state.feeReimbursed = action.payload
		},
		setVipTradeHistoryTableFilter: (
			state,
			action: PayloadAction<VipTradeHistoryTableFilter | null>
		) => {
			if (!action.payload) {
				state.vipTradeHistoryTableFilter = null
			} else {
				state.vipTradeHistoryTableFilter = action.payload
			}
		},
	},
})

export const {
	setVipData,
	setThirtyDayVolume,
	setQueryStatus,
	setAccountClaimPeriod,
	setFeeReimbursed,
	setVipTradeHistoryTableFilter,
} = vip.actions

export default vip.reducer
