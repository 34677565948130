const PERMIT2_DOMAIN_NAME = 'Permit2';
const PERMIT_STRUCT = '((address token,uint160 amount,uint48 expiration,uint48 nonce) details,address spender,uint256 sigDeadline)';
export const PERMIT_INPUT_TYPES = [
    'address',
    'uint160',
    'uint48',
    'uint48',
    'address',
    'address',
    'uint256',
];
const PERMIT_DETAILS = [
    { name: 'token', type: 'address' },
    { name: 'amount', type: 'uint160' },
    { name: 'expiration', type: 'uint48' },
    { name: 'nonce', type: 'uint48' },
];
const PERMIT_TYPES = {
    PermitSingle: [
        { name: 'details', type: 'PermitDetails' },
        { name: 'spender', type: 'address' },
        { name: 'sigDeadline', type: 'uint256' },
    ],
    PermitDetails: PERMIT_DETAILS,
};
export { PERMIT2_DOMAIN_NAME, PERMIT_STRUCT, PERMIT_DETAILS, PERMIT_TYPES };
export const DEFAULT_1INCH_SLIPPAGE = 3;
export const ONE_INCH_PROTOCOLS = 'OPTIMISM_UNISWAP_V3,OPTIMISM_SYNTHETIX,OPTIMISM_SYNTHETIX_WRAPPER,OPTIMISM_ONE_INCH_LIMIT_ORDER,OPTIMISM_ONE_INCH_LIMIT_ORDER_V2,OPTIMISM_CURVE,OPTIMISM_BALANCER_V2,OPTIMISM_VELODROME,OPTIMISM_KYBERSWAP_ELASTIC';
export const KWENTA_ONE_INCH_REFERRAL_ADDRESS = '0x08e30BFEE9B73c18F9770288DDd13203A4887460';
