const abi = [
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'feeAmount',
                type: 'uint256',
            },
        ],
        name: 'FeeRequired',
        type: 'error',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'oracleContract',
                type: 'address',
            },
            {
                internalType: 'bytes',
                name: 'oracleQuery',
                type: 'bytes',
            },
        ],
        name: 'OracleDataRequired',
        type: 'error',
    },
    {
        inputs: [
            {
                internalType: 'bytes[]',
                name: 'revertReasons',
                type: 'bytes[]',
            },
        ],
        name: 'Errors',
        type: 'error',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'oracleContract',
                type: 'address',
            },
            {
                internalType: 'bytes',
                name: 'oracleQuery',
                type: 'bytes',
            },
            {
                internalType: 'uint256',
                name: 'fee',
                type: 'uint256',
            },
        ],
        name: 'OracleDataRequired',
        type: 'error',
    },
    {
        inputs: [
            {
                internalType: 'bytes',
                name: 'signedOffchainData',
                type: 'bytes',
            },
        ],
        name: 'fulfillOracleQuery',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'oracleId',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
export default abi;
