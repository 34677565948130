const abi = [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'AddressInsufficientBalance',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'uint48', name: 'deadline', type: 'uint48' }],
        name: 'ERC2771ForwarderExpiredRequest',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'address', name: 'signer', type: 'address' },
            { internalType: 'address', name: 'from', type: 'address' },
        ],
        name: 'ERC2771ForwarderInvalidSigner',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'requestedValue', type: 'uint256' },
            { internalType: 'uint256', name: 'msgValue', type: 'uint256' },
        ],
        name: 'ERC2771ForwarderMismatchedValue',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'address', name: 'target', type: 'address' },
            { internalType: 'address', name: 'forwarder', type: 'address' },
        ],
        name: 'ERC2771UntrustfulTarget',
        type: 'error',
    },
    { inputs: [], name: 'FailedInnerCall', type: 'error' },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'currentNonce', type: 'uint256' },
        ],
        name: 'InvalidAccountNonce',
        type: 'error',
    },
    { inputs: [], name: 'InvalidShortString', type: 'error' },
    {
        inputs: [{ internalType: 'string', name: 'str', type: 'string' }],
        name: 'StringTooLong',
        type: 'error',
    },
    { anonymous: false, inputs: [], name: 'EIP712DomainChanged', type: 'event' },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'signer', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { indexed: false, internalType: 'bool', name: 'success', type: 'bool' },
        ],
        name: 'ExecutedForwardRequest',
        type: 'event',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Call[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'aggregate',
        outputs: [
            { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
            { internalType: 'bytes[]', name: 'returnData', type: 'bytes[]' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Call3[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'aggregate3',
        outputs: [
            {
                components: [
                    { internalType: 'bool', name: 'success', type: 'bool' },
                    { internalType: 'bytes', name: 'returnData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Result[]',
                name: 'returnData',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
                    { internalType: 'uint256', name: 'value', type: 'uint256' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Call3Value[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'aggregate3Value',
        outputs: [
            {
                components: [
                    { internalType: 'bool', name: 'success', type: 'bool' },
                    { internalType: 'bytes', name: 'returnData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Result[]',
                name: 'returnData',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Call[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'blockAndAggregate',
        outputs: [
            { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
            { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
            {
                components: [
                    { internalType: 'bool', name: 'success', type: 'bool' },
                    { internalType: 'bytes', name: 'returnData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Result[]',
                name: 'returnData',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'eip712Domain',
        outputs: [
            { internalType: 'bytes1', name: 'fields', type: 'bytes1' },
            { internalType: 'string', name: 'name', type: 'string' },
            { internalType: 'string', name: 'version', type: 'string' },
            { internalType: 'uint256', name: 'chainId', type: 'uint256' },
            { internalType: 'address', name: 'verifyingContract', type: 'address' },
            { internalType: 'bytes32', name: 'salt', type: 'bytes32' },
            { internalType: 'uint256[]', name: 'extensions', type: 'uint256[]' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'value', type: 'uint256' },
                    { internalType: 'uint256', name: 'gas', type: 'uint256' },
                    { internalType: 'uint48', name: 'deadline', type: 'uint48' },
                    { internalType: 'bytes', name: 'data', type: 'bytes' },
                    { internalType: 'bytes', name: 'signature', type: 'bytes' },
                ],
                internalType: 'struct ERC2771Forwarder.ForwardRequestData',
                name: 'request',
                type: 'tuple',
            },
        ],
        name: 'execute',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'value', type: 'uint256' },
                    { internalType: 'uint256', name: 'gas', type: 'uint256' },
                    { internalType: 'uint48', name: 'deadline', type: 'uint48' },
                    { internalType: 'bytes', name: 'data', type: 'bytes' },
                    { internalType: 'bytes', name: 'signature', type: 'bytes' },
                ],
                internalType: 'struct ERC2771Forwarder.ForwardRequestData[]',
                name: 'requests',
                type: 'tuple[]',
            },
        ],
        name: 'executeBatch',
        outputs: [
            {
                components: [
                    { internalType: 'bool', name: 'success', type: 'bool' },
                    { internalType: 'bytes', name: 'returnData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Result[]',
                name: 'returnData',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'value', type: 'uint256' },
                    { internalType: 'uint256', name: 'gas', type: 'uint256' },
                    { internalType: 'uint48', name: 'deadline', type: 'uint48' },
                    { internalType: 'bytes', name: 'data', type: 'bytes' },
                    { internalType: 'bytes', name: 'signature', type: 'bytes' },
                ],
                internalType: 'struct ERC2771Forwarder.ForwardRequestData[]',
                name: 'requests',
                type: 'tuple[]',
            },
            { internalType: 'address payable', name: 'refundReceiver', type: 'address' },
        ],
        name: 'executeBatch',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getBasefee',
        outputs: [{ internalType: 'uint256', name: 'basefee', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
        name: 'getBlockHash',
        outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getBlockNumber',
        outputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getChainId',
        outputs: [{ internalType: 'uint256', name: 'chainid', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockCoinbase',
        outputs: [{ internalType: 'address', name: 'coinbase', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockGasLimit',
        outputs: [{ internalType: 'uint256', name: 'gaslimit', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockTimestamp',
        outputs: [{ internalType: 'uint256', name: 'timestamp', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
        name: 'getEthBalance',
        outputs: [{ internalType: 'uint256', name: 'balance', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getLastBlockHash',
        outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getPrevRandao',
        outputs: [{ internalType: 'uint256', name: 'prevrandao', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'nonces',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Call[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'tryAggregate',
        outputs: [
            {
                components: [
                    { internalType: 'bool', name: 'success', type: 'bool' },
                    { internalType: 'bytes', name: 'returnData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Result[]',
                name: 'returnData',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Call[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'tryBlockAndAggregate',
        outputs: [
            { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
            { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
            {
                components: [
                    { internalType: 'bool', name: 'success', type: 'bool' },
                    { internalType: 'bytes', name: 'returnData', type: 'bytes' },
                ],
                internalType: 'struct TrustedMulticallForwarder.Result[]',
                name: 'returnData',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'value', type: 'uint256' },
                    { internalType: 'uint256', name: 'gas', type: 'uint256' },
                    { internalType: 'uint48', name: 'deadline', type: 'uint48' },
                    { internalType: 'bytes', name: 'data', type: 'bytes' },
                    { internalType: 'bytes', name: 'signature', type: 'bytes' },
                ],
                internalType: 'struct ERC2771Forwarder.ForwardRequestData',
                name: 'request',
                type: 'tuple',
            },
        ],
        name: 'verify',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
];
export default abi;
