import type KwentaSDK from '@kwenta/sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GHOST_KEY } from 'state/constants'
import type { ThunkConfig } from 'state/types'
import logError from 'utils/logError'
import type { BlogPost } from './types'

export const fetchFuturesStats = createAsyncThunk<
	Awaited<ReturnType<KwentaSDK['stats']['getFuturesStats']>>,
	void,
	ThunkConfig
>('home/fetchFuturesStats', async (_, { extra: { sdk } }) => {
	try {
		return await sdk.stats.getFuturesStats(10)
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchBlogPosts = createAsyncThunk<BlogPost[], void, ThunkConfig>(
	'home/fetchBlogPosts',
	async () => {
		try {
			const result = await axios.get<{ posts: BlogPost[] }>(
				`https://blog.kwenta.io/ghost/api/content/posts/?key=${GHOST_KEY}&limit=3`,
				{
					headers: {
						'Accept-Version': '5.0',
					},
				}
			)

			if (result.status !== 200) {
				return []
			}

			return result.data.posts
		} catch (error) {
			logError(error)
			return []
		}
	}
)
