import { wei } from '@kwenta/wei';
import { arbitrum, optimism } from 'viem/chains';
export const DEFAULT_NUMBER_OF_FUTURES_FEE = BigInt(9999);
export const EPOCH_START = {
    11155420: 1665878400,
    10: 1668556800,
    42161: 1721044800,
};
export const EPOCH_OP_REFERRAL_START = 1722859200; // 2024-08-05 12:00:00 UTC, Monday
export const WEEK = 604800;
export const VESTING_ENTRY_PAGE_SIZE = BigInt(999999);
export const DECAY_RATE = 0.0205;
export const INITIAL_WEEKLY_SUPPLY = '14463369230769230769230';
export const STAKING_REWARDS_RATIO = 0.6;
export const TRADING_REWARDS_RATIO = 0.05;
export const TRADING_REWARDS_CUTOFF_EPOCH = 13;
export const STAKING_V2_REWARDS_CUTOFF_EPOCH = 84;
export const STAKING_V2_REWARDS_END_EPOCH = 89;
export const ARB_TRADING_REWARDS_END_EPOCH = 14;
export const OP_REWARDS_CUTOFF_EPOCH = 22;
export const REFERRAL_PROGRAM_START_EPOCH = 85;
export const REFERRAL_PROGRAM_END_EPOCH = 89;
export const OP_REWARDS_END_EPOCH = 47;
export const SUPPLY_RATE = wei(1).sub(wei(DECAY_RATE));
export const STAKING_ENDPOINT_OP_MAINNET = 'https://subgraph.satsuma-prod.com/05943208e921/kwenta/staking-v2/api';
export const STAKING_ENDPOINTS = {
    10: STAKING_ENDPOINT_OP_MAINNET,
};
export const EPOCH_CONFIGS = {
    default: { networkId: optimism.id, epochStart: EPOCH_START[optimism.id] },
    arbitrum: { networkId: arbitrum.id, epochStart: EPOCH_START[arbitrum.id] },
    opReferral: { networkId: optimism.id, epochStart: EPOCH_OP_REFERRAL_START },
};
