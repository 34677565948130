import common from './common'
import darkTheme from './dark'

const goldColors = {
	color1: '#BE9461',
	color2: '#9C6C3C',
	color3: '#E4B378',
	color4: '#B98C55',
}

export const themeColors = {
	dark: darkTheme,
}

const colors = {
	black: '#000000',
	silver: '#8A939F',
	white: '#FFFFFF',
	red: '#CB366D',
	green: '#66DD84',
	yellow: '#FFDF6D',
	goldColors,
	gold: `linear-gradient(180deg, ${goldColors.color1} 0%, ${goldColors.color2} 100%)`,
	// network colors
	mainnet: '#7FD482', // green
	testnet: '#F6C343', // yellow
	noNetwork: '#EF6868', // red
	connectedDefault: goldColors.color1,
	common,
}

export default colors
