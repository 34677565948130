import { wei } from '@kwenta/wei';
import PerennialSDK, { Big6Math, ChainMarkets, Hour, MultiInvokerAddresses, OrderTypes, PositionSide as PerennialPositionSide, TriggerComparison, Year, addressToAsset, calcEstExecutionPrice, calcFundingRates, calcLeverage, calcLiquidationPrice, calcMaxLeverage, calcNotional, calcSkew, calcTakerLiquidity, calcTradeFee, mergeMultiInvokerTxs, notEmpty, } from '@perennial/sdk';
import { Bucket } from '@perennial/sdk/dist/types/gql/graphql';
import axios from 'axios';
import { groupBy, map, sumBy } from 'lodash';
import { getAddress, zeroAddress } from 'viem';
import { EST_TRADE_TX_COST_USDC, MARKETS, NEWLY_LISTED_MARKETS, PERENNIAL_EXCLUDED_MARKETS, PERIOD_IN_SECONDS, PYTH_SERVER_PRIMARY, WEEK, ZERO_WEI, } from '../constants';
import { ALCHEMY_NETWORK_LOOKUP } from '../constants/network';
import { checkMarketStatusPyth, queryDelegatesForAccount, queryOpenOrders, queryPerennialFuturesFee, queryPerennialMarketOrders, queryPnlUpdates, querySubAccountsForAccount, } from '../queries/perennial';
import { FuturesMarginType, OrderTypeEnum, PerennialArbNetworkIds, PerpsProvider, PositionSide, PotentialTradeStatus, } from '../types';
import { MarketKeyByAsset, bigIntMax, getDefaultPriceImpact, getDisplayAsset, getMarketCategory, getMarketName, getPerennialSubgraphUrl, getReasonFromCode, getTradeStatusMessage, } from '../utils';
import { assetToFuturesMarketAsset, assetToMarketKey, formatPerennialOrderType, formatPerennialPositionHistory, fromWei6, futuresMarketAssetToAsset, getConditionalOrderFee, getConditionalOrderParam, getPriceForTradePreview, getSide, getStatusCode, interfaceFee, mapMarkets, maybeDeriveSupportedAsset, openOrderToConditionalOrder, perennialSdkTradeToFuturesTrade, toWei6, } from '../utils/perennial';
export default class PerennialService {
    constructor() {
        const pythUrl = PYTH_SERVER_PRIMARY;
        const perennialSDKMainnet = new PerennialSDK({
            rpcUrl: `https://${ALCHEMY_NETWORK_LOOKUP[PerennialArbNetworkIds.ARB_MAINNET]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
            chainId: PerennialArbNetworkIds.ARB_MAINNET,
            graphUrl: getPerennialSubgraphUrl(PerennialArbNetworkIds.ARB_MAINNET),
            pythUrl,
        });
        const perennialSDKSepolia = new PerennialSDK({
            rpcUrl: `https://${ALCHEMY_NETWORK_LOOKUP[PerennialArbNetworkIds.ARB_SEPOLIA]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
            chainId: PerennialArbNetworkIds.ARB_SEPOLIA,
            graphUrl: getPerennialSubgraphUrl(PerennialArbNetworkIds.ARB_SEPOLIA),
            pythUrl,
        });
        this.perennialSdks = {
            [PerennialArbNetworkIds.ARB_MAINNET]: perennialSDKMainnet,
            [PerennialArbNetworkIds.ARB_SEPOLIA]: perennialSDKSepolia,
        };
        this.supportedMarketsByChain = {
            [PerennialArbNetworkIds.ARB_MAINNET]: mapMarkets(PerennialArbNetworkIds.ARB_MAINNET),
            [PerennialArbNetworkIds.ARB_SEPOLIA]: mapMarkets(PerennialArbNetworkIds.ARB_SEPOLIA),
        };
    }
    perennialSdk(chainId) {
        return this.perennialSdks[chainId ?? PerennialArbNetworkIds.ARB_MAINNET];
    }
    markets(chainId) {
        return this.supportedMarketsByChain[chainId ?? PerennialArbNetworkIds.ARB_MAINNET];
    }
    supportedMarkets(chainId) {
        return this.supportedMarketsByChain[chainId ?? PerennialArbNetworkIds.ARB_MAINNET].map((m) => m.asset);
    }
    // Market factory approval required for all transactions
    async checkMarketFactoryApproval(wallet, chainId) {
        const operatorApproved = await this.perennialSdk(chainId).operator.read.marketFactoryApproval({
            address: wallet,
        });
        return operatorApproved;
    }
    async getMarkets(chainId) {
        const marketSnapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
            address: zeroAddress,
        });
        if (!marketSnapshots) {
            return [];
        }
        const marketKeys = Object.keys(marketSnapshots.market);
        const closeableMarekts = Object.values(MARKETS).filter((m) => !!m.isClosable);
        const marketStatuses = await Promise.all(closeableMarekts.map(async (m) => {
            const response = await checkMarketStatusPyth(m.pythId);
            return { key: m.key, response };
        }));
        const settlementFees = await this.perennialSdk(chainId).markets.read.settlementFees();
        return marketKeys
            .map((key) => {
            const marketSnapshot = marketSnapshots.market[key];
            if (!marketSnapshot) {
                return null;
            }
            const { global: { latestPrice }, nextPosition: { long, short }, } = marketSnapshot;
            const longOi = Big6Math.mul(long, latestPrice);
            const shortOi = Big6Math.mul(short, latestPrice);
            const longRate = calcFundingRates(marketSnapshot.fundingRate.long);
            const shortRate = calcFundingRates(marketSnapshot.fundingRate.short);
            const calculatedSkew = calcSkew(marketSnapshot);
            const marketSize = toWei6(longOi).add(toWei6(shortOi));
            const marketAddress = marketSnapshot.marketAddress;
            const asset = assetToFuturesMarketAsset(key);
            const marketKey = MarketKeyByAsset[asset];
            const liquidityData = calcTakerLiquidity(marketSnapshot);
            const maxLeverage = wei(1).div(toWei6(marketSnapshot.riskParameter.margin));
            const marketStatus = marketStatuses.find((s) => s.key === marketKey)?.response;
            const settlementFee = settlementFees[key];
            return {
                provider: PerpsProvider.PERENNIAL_V2_ARB,
                marginType: FuturesMarginType.ISOLATED_MARGIN,
                marketId: marketAddress,
                marketAddress,
                category: getMarketCategory(marketKey),
                marketName: getMarketName(asset),
                asset,
                perennialAsset: key,
                currentFundingRate: {
                    long: toWei6(longRate.hourlyFunding),
                    short: toWei6(shortRate.hourlyFunding),
                },
                currentFundingVelocity: toWei6(0n),
                feeRates: {
                    // TODO: Should we include the dynamic fees here?
                    makerFee: toWei6(marketSnapshot.riskParameter.makerFee.linearFee),
                    takerFee: toWei6(marketSnapshot.riskParameter.takerFee.linearFee),
                },
                openInterest: {
                    long: toWei6(long),
                    short: toWei6(short),
                    longUSD: toWei6(longOi),
                    shortUSD: toWei6(shortOi),
                    shortPct: marketSize.gt(0) ? toWei6(shortOi).div(marketSize).toNumber() : 0,
                    longPct: marketSize.gt(0) ? toWei6(longOi).div(marketSize).toNumber() : 0,
                },
                marketDebt: toWei6(0n), // TODO: ?
                marketSkew: toWei6(calculatedSkew?.skew ?? 0n),
                contractMaxLeverage: maxLeverage,
                appMaxLeverage: maxLeverage,
                marketLimitUsd: {
                    long: toWei6(liquidityData.totalLongLiquidity).mul(toWei6(latestPrice)),
                    short: toWei6(liquidityData.totalShortLiquidity).mul(toWei6(latestPrice)),
                },
                marketLimitNative: {
                    long: toWei6(liquidityData.totalLongLiquidity),
                    short: toWei6(liquidityData.totalShortLiquidity),
                },
                marginParameter: toWei6(marketSnapshot.riskParameter.margin),
                minInitialMargin: toWei6(marketSnapshot.riskParameter.minMargin),
                settlementFee: toWei6(settlementFee.totalCost),
                isSuspended: marketStatus ? !marketStatus.is_open : false,
                isCloseable: closeableMarekts.some((m) => m.key === marketKey),
                marketHours: {
                    nextOpen: marketStatus ? marketStatus.next_open : null,
                    nextClose: marketStatus ? marketStatus.next_close : null,
                },
                marketClosureReason: getReasonFromCode(2),
                settings: {
                // TODO: Settings
                },
                newListing: !!NEWLY_LISTED_MARKETS[chainId ?? PerennialArbNetworkIds.ARB_MAINNET]?.includes(marketKey),
            };
        })
            .filter(notEmpty)
            .filter((market) => !PERENNIAL_EXCLUDED_MARKETS.includes(market.asset));
    }
    async getActivePositions(address, { chainId, }) {
        const snapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
            address: address,
        });
        if (!snapshots?.user) {
            return [];
        }
        const pendingOrders = await this.getConditionalOrders(address, { chainId });
        const userPositions = snapshots.user;
        const marketKeys = Object.keys(userPositions).filter((key) => {
            return snapshots?.market[key] && userPositions[key].magnitude > 0n;
        });
        const pnlResults = await this.perennialSdk(chainId).markets.read.activePositionsPnl({
            address: getAddress(address),
            markets: marketKeys,
            marketSnapshots: snapshots,
            markToMarket: false,
        });
        const positionPromises = marketKeys.map(async (key) => {
            const marketSnapshot = snapshots?.market[key];
            if (!marketSnapshot) {
                return undefined;
            }
            const reservedMargin = this.reservedMarginForMarket(pendingOrders, key);
            // TODO: cache activePositionPnl
            const positionPnl = pnlResults[key];
            const userSnapshot = userPositions[key];
            if (userSnapshot.nextMagnitude === 0n) {
                return undefined;
            }
            if (userSnapshot.side === PerennialPositionSide.maker ||
                userSnapshot.side === PerennialPositionSide.none) {
                return undefined;
            }
            let collateral = userSnapshot.local.collateral - reservedMargin;
            collateral = collateral < 0 ? 0n : collateral;
            const latestPrice = marketSnapshot?.global.latestPrice ?? 0n;
            const asset = assetToFuturesMarketAsset(key);
            const liquidationData = calcLiquidationPrice({
                marketSnapshot,
                collateral: collateral,
                position: userSnapshot.nextMagnitude,
            });
            const liquidationPrice = liquidationData[userSnapshot.nextSide];
            const canLiquidatePosition = userSnapshot.nextSide === PerennialPositionSide.long
                ? latestPrice < liquidationPrice
                : latestPrice > liquidationPrice;
            const initialLeverage = positionPnl
                ? Big6Math.abs(Big6Math.div(Big6Math.mul(latestPrice, userSnapshot.nextMagnitude), positionPnl.startCollateral))
                : 0n;
            const accessibleMargin = userSnapshot.local.collateral - bigIntMax(userSnapshot.margin, userSnapshot.nextMargin);
            const maxLeverage = calcMaxLeverage({
                margin: marketSnapshot.riskParameter.margin,
                minMargin: marketSnapshot.riskParameter.minMargin,
                collateral: userSnapshot.local.collateral,
            });
            const realizedFundingAndInterest = (positionPnl.pendingMarkToMarketAccumulations?.funding ?? 0n) +
                (positionPnl.pendingMarkToMarketAccumulations?.interest ?? 0n);
            const percentDenominator = positionPnl.startCollateral + (positionPnl.netDeposits > 0n ? positionPnl.netDeposits : 0n);
            const totalNet = positionPnl.netPnl + realizedFundingAndInterest;
            const rPnl = {
                pnl: toWei6(positionPnl.pnlAccumulations.pnl).add(positionPnl.pnlAccumulations.offset),
                netPnl: toWei6(positionPnl.netPnl).add(toWei6(realizedFundingAndInterest)),
                netPnlPct: toWei6(totalNet).div(toWei6(percentDenominator)),
            };
            const upnl = positionPnl.realtime - positionPnl.netPnl;
            const upnlPct = positionPnl.realtimePercent - positionPnl.netPnlPercent;
            const uPnl = {
                pnl: toWei6(upnl),
                pnlPct: toWei6(upnlPct / 100n),
            };
            const totalPnl = {
                pnl: rPnl.pnl.add(positionPnl.realtime),
                netPnl: rPnl.netPnl.add(uPnl.pnl),
                netPnlPct: uPnl.pnlPct,
            };
            const activePosition = {
                provider: PerpsProvider.PERENNIAL_V2_ARB,
                asset,
                marketAddress: marketSnapshot.marketAddress,
                account: address,
                details: {
                    id: positionPnl.positionId.toString(),
                    side: getSide(userSnapshot.nextSide),
                    size: toWei6(userSnapshot.nextMagnitude),
                    status: 'open',
                    accountType: FuturesMarginType.ISOLATED_MARGIN,
                    timestamp: Number(pnlResults[key].startVersion) * 1000,
                    openTimestamp: Number(pnlResults[key].startVersion) * 1000,
                    closeTimestamp: undefined,
                    stats: {
                        totalVolume: toWei6(positionPnl.totalNotional),
                        trades: Number(positionPnl.trades),
                        totalDeposits: toWei6(positionPnl.netDeposits),
                        netTransfers: toWei6(BigInt(0)), // TODO: Perennial pos net transfers
                    },
                    margin: {
                        remainingMargin: toWei6(collateral),
                        accessibleMargin: toWei6(accessibleMargin),
                        initialLeverage: toWei6(initialLeverage),
                        notionalValue: toWei6(userSnapshot.nextNotional),
                        initialMargin: toWei6(positionPnl?.startCollateral ?? 0n),
                        marginRatio: toWei6(userSnapshot.nextNotional).eq(0)
                            ? wei(0)
                            : toWei6(userSnapshot.local.collateral).div(toWei6(userSnapshot.nextNotional).abs()),
                        leverage: toWei6(userSnapshot.nextLeverage),
                        maxLeverage: toWei6(maxLeverage),
                    },
                    price: {
                        lastPrice: userSnapshot.oracleVersions[0].price
                            ? toWei6(userSnapshot.oracleVersions[0].price)
                            : undefined,
                        liquidationPrice: liquidationPrice ? toWei6(liquidationPrice) : undefined,
                        avgEntryPrice: positionPnl?.averageEntryPrice || userSnapshot
                            ? toWei6(positionPnl?.averageEntryPrice ?? userSnapshot.oracleVersions[0].price)
                            : undefined,
                        exitPrice: null,
                        entryPrice: toWei6(positionPnl.startPrice),
                    },
                    pnl: {
                        rPnl,
                        uPnl,
                        totalPnl,
                    },
                    liquidation: {
                        canLiquidatePosition,
                        isLiquidated: false,
                    },
                    fees: {
                        netFunding: toWei6(positionPnl.pnlAccumulations.funding),
                        accruedFunding: toWei6(positionPnl.pendingMarkToMarketAccumulations?.funding ?? 0n).add(positionPnl.pnlAccumulations.funding),
                        owedInterest: toWei6(positionPnl.pendingMarkToMarketAccumulations?.interest ?? 0n).add(positionPnl.pnlAccumulations.interest),
                        feesPaid: toWei6(positionPnl.totalFees),
                    },
                },
            };
            return activePosition;
        });
        const positions = (await Promise.all(positionPromises)).filter(notEmpty);
        return positions;
    }
    async getPositions(params) {
        const { walletAddress, pageLength, page, marketAsset } = params;
        const chainId = params.chainId ?? PerennialArbNetworkIds.ARB_MAINNET;
        let markets = [];
        if (marketAsset) {
            const asSupportedAsset = maybeDeriveSupportedAsset(marketAsset);
            if (asSupportedAsset) {
                markets = [asSupportedAsset];
            }
        }
        else {
            markets = this.supportedMarkets(chainId);
        }
        const activePositions = await this.getActivePositions(walletAddress, { chainId });
        if (params.status === 'open') {
            return activePositions;
        }
        const posHistory = await this.perennialSdk(chainId).markets.read.historicalPositions({
            address: walletAddress,
            markets,
            first: pageLength ?? 25,
            skip: page ?? 0,
        });
        const positions = posHistory.reduce((acc, position) => {
            const market = markets.find((m) => m === position.market);
            if (!market)
                return acc;
            acc.push(position);
            return acc;
        }, []);
        const positionHistory = formatPerennialPositionHistory(walletAddress, positions);
        return [...activePositions, ...positionHistory];
    }
    async getTradesForPosition(params) {
        const { walletAddress: address, market, first, skip, positionId } = params;
        const chainId = params.chainId ?? PerennialArbNetworkIds.ARB_MAINNET;
        const history = await this.perennialSdk(chainId).markets.read.subPositions({
            positionId,
            address,
            market: futuresMarketAssetToAsset(market),
            first: first ?? 25,
            skip: skip ?? 0,
        });
        if (!history) {
            return [];
        }
        return history
            .map((h) => perennialSdkTradeToFuturesTrade(h, address, positionId.toString()))
            .filter((h) => !h.sizeDelta.eq(0));
    }
    async getTradesForActivePosition(params) {
        const { walletAddress: address, first, skip, market, positionId } = params;
        const chainId = params.chainId ?? PerennialArbNetworkIds.ARB_MAINNET;
        const history = await this.perennialSdk(chainId).markets.read.activePositionHistory({
            positionId,
            address,
            market: futuresMarketAssetToAsset(market),
            skip: skip ?? 0,
            first: first ?? 25,
        });
        if (!history) {
            return [];
        }
        return history
            .map((h) => perennialSdkTradeToFuturesTrade(h, address, positionId.toString()))
            .filter((h) => !h.sizeDelta.eq(0));
    }
    async getTradeHistory(params) {
        const address = params.walletAddress;
        const chainId = params.chainId ?? PerennialArbNetworkIds.ARB_MAINNET;
        const currentTimestamp = BigInt(Math.floor(Date.now() / 1000));
        const oneYearAgo = currentTimestamp - BigInt(WEEK * 53);
        const results = await this.perennialSdk(chainId).markets.read.tradeHistory({
            address,
            fromTs: oneYearAgo,
            toTs: currentTimestamp,
        });
        if (!results) {
            return [];
        }
        return results
            .map((h) => perennialSdkTradeToFuturesTrade(h, address, h.version.toString()))
            .sort((a, b) => b.timestamp - a.timestamp)
            .filter((trade) => !trade.sizeDelta.eq(0));
    }
    async getGlobalTradesHistory(params) {
        const { marketAsset, chainId, minTimestamp, maxTimestamp, pageSize } = params;
        const asSupportedAsset = maybeDeriveSupportedAsset(marketAsset);
        const marketAddress = asSupportedAsset ? ChainMarkets[chainId][asSupportedAsset] : undefined;
        if (!marketAddress)
            return [];
        return queryPerennialMarketOrders({
            chainId,
            marketAddress,
            minTimestamp,
            maxTimestamp,
            pageSize,
        });
    }
    async getMarketFundingRatesHistory(marketAsset, chainId, periodLength = PERIOD_IN_SECONDS.TWO_WEEKS) {
        const asSupportedAsset = maybeDeriveSupportedAsset(marketAsset);
        const market = asSupportedAsset ? ChainMarkets[chainId][asSupportedAsset] : undefined;
        if (!market || !asSupportedAsset) {
            return [];
        }
        const toTS = BigInt(Math.floor(Date.now() / 1000));
        const fromTS = BigInt(Math.floor(Date.now() / 1000) - periodLength);
        const data = await this.perennialSdk(chainId).markets.read.marketsHistoricalData({
            markets: [asSupportedAsset],
            fromTs: fromTS,
            toTs: toTS,
            bucket: Bucket.Hourly,
        });
        if (!data) {
            return [];
        }
        const groupedRates = groupBy([...data[asSupportedAsset].interestRates, ...data[asSupportedAsset].fundingRates], 'timestamp');
        const summedRates = map(groupedRates, (rates, timestamp) => ({
            timestamp,
            longAPR: sumBy(rates, 'longAPR'),
            shortAPR: sumBy(rates, 'shortAPR'),
        }));
        return summedRates.map((rate) => {
            return {
                timestamp: Number(rate.timestamp) * 1000,
                longRate: (Number(rate.longAPR) / Number(Big6Math.BASE * Year)) * Number(Hour) * 1e18,
                shortRate: (Number(rate.shortAPR) / Number(Big6Math.BASE * Year)) * Number(Hour) * 1e18,
            };
        });
    }
    async getUsdcBalanceAndAllowance(walletAddress, chainId) {
        const usdcContract = this.perennialSdk().contracts.getUSDCContract();
        const usdcBalance = await usdcContract.read.balanceOf([walletAddress]);
        const allowance = await usdcContract.read.allowance([
            walletAddress,
            MultiInvokerAddresses[chainId ?? PerennialArbNetworkIds.ARB_MAINNET],
        ]);
        return { usdcBalance, allowance };
    }
    async getBalanceInfo(walletAddress, chainId) {
        const { usdcBalance, allowance } = await this.getUsdcBalanceAndAllowance(walletAddress, chainId);
        const factoryApproved = await this.checkMarketFactoryApproval(walletAddress, chainId);
        const idleMargin = await this.getIdleMarginInMarkets(walletAddress, { chainId });
        return {
            usdcBalance,
            allowance,
            factoryApproved,
            idleMarginByMarket: idleMargin.marketsWithIdleMargin,
            totalMarginByMarket: idleMargin.marketsTotalMargin,
            totalIdleMargin: idleMargin.totalIdleInMarkets,
        };
    }
    async getIdleMarginInMarkets(walletAddress, options) {
        let _marketSnapshots = options?.marketSnapshots;
        if (!_marketSnapshots) {
            const marketOracles = await this.perennialSdk(options?.chainId).markets.read.marketOracles();
            _marketSnapshots = await this.perennialSdk(options?.chainId).markets.read.marketSnapshots({
                address: walletAddress,
                marketOracles,
            });
        }
        const snapshots = _marketSnapshots ??
            (await this.perennialSdk(options?.chainId).markets.read.marketSnapshots({
                address: walletAddress,
            }));
        if (!snapshots?.user) {
            return {
                totalIdleInMarkets: wei(0),
                marketsWithIdleMargin: {},
                marketsTotalMargin: {},
            };
        }
        const pendingOrders = await this.getConditionalOrders(walletAddress, {
            chainId: options?.chainId,
        });
        const userPositions = snapshots.user;
        return Object.keys(userPositions).reduce((acc, market) => {
            const marketSnapshot = userPositions[market];
            const reservedMargin = pendingOrders
                .filter((order) => order.marketKey === assetToMarketKey(marketSnapshot.market) && order.marginDelta.gt(0))
                .reduce((acc, order) => acc + fromWei6(order.marginDelta), BigInt(0));
            if (marketSnapshot.local.collateral > 0n && marketSnapshot.nextMagnitude === 0n) {
                const key = assetToFuturesMarketAsset(marketSnapshot.market);
                const collateral = toWei6(marketSnapshot.local.collateral);
                const idle = collateral.sub(toWei6(reservedMargin));
                const total = idle.gt(0) ? idle : wei(0);
                acc.marketsTotalMargin[key] = collateral;
                acc.marketsWithIdleMargin[key] = total;
                acc.totalIdleInMarkets = acc.totalIdleInMarkets.add(total);
            }
            return acc;
        }, {
            marketsWithIdleMargin: {},
            marketsTotalMargin: {},
            totalIdleInMarkets: wei(0),
        });
    }
    async getTradePreview({ walletAddress, market, tradeParams, chainId, }) {
        const marketOracles = await this.perennialSdk(chainId).markets.read.marketOracles();
        const marketSnapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
            address: walletAddress,
            marketOracles,
        });
        if (!marketSnapshots || !marketSnapshots.user) {
            throw new Error('No market snapshots found'); // TODO: Or handle this some other way
        }
        if (!marketSnapshots || !marketSnapshots.user) {
            throw new Error('No market snapshots found');
        }
        const asset = futuresMarketAssetToAsset(market);
        const userPositionData = marketSnapshots.user[asset];
        const marketSnapshot = marketSnapshots.market[asset];
        if (!marketSnapshot)
            throw new Error('No market snapshot');
        const price = getPriceForTradePreview({
            latestPrice: marketSnapshot.global.latestPrice,
            orderPrice: tradeParams?.orderPrice,
            side: tradeParams.positionSide,
        });
        const pendingOrders = await this.getConditionalOrders(walletAddress, { chainId });
        const reservedMargin = this.reservedMarginForMarket(pendingOrders, asset);
        const newPosition = tradeParams.sizeDelta + userPositionData.nextMagnitude;
        const existingCollateral = (userPositionData?.local.collateral ?? 0n) - reservedMargin;
        const newCollateral = existingCollateral + tradeParams.marginDelta;
        const positionSide = tradeParams.positionSide === PositionSide.LONG
            ? PerennialPositionSide.long
            : PerennialPositionSide.short;
        const newLeverage = calcLeverage(price, newPosition, newCollateral);
        const tradeFee = calcTradeFee({
            positionDelta: tradeParams.sizeDelta,
            marketSnapshot,
            side: positionSide,
        });
        const liquidationPrice = toWei6(newLeverage).gt(1)
            ? calcLiquidationPrice({
                marketSnapshot,
                collateral: newCollateral,
                position: newPosition,
                limitPrice: tradeParams.orderPrice ?? undefined,
            })[positionSide]
            : BigInt(0);
        const estEntryPrice = !Big6Math.isZero(tradeParams.sizeDelta)
            ? calcEstExecutionPrice({
                side: positionSide,
                indexPrice: price,
                positionDelta: Big6Math.abs(tradeParams.sizeDelta),
                marketSnapshot,
            })
            : price;
        const liquidityData = calcTakerLiquidity(marketSnapshot);
        const availableLiquidity = positionSide === PerennialPositionSide.long
            ? liquidityData.availableLongLiquidity
            : liquidityData.availableShortLiquidity;
        const maxLeverage = calcMaxLeverage({
            margin: marketSnapshot.riskParameter.margin,
            minMargin: marketSnapshot.riskParameter.minMargin,
            collateral: newCollateral,
        });
        // Errors
        const requiredMargin = Big6Math.max(marketSnapshot.riskParameter.minMargin, Big6Math.mul(marketSnapshot.riskParameter.margin, calcNotional(newPosition, price)));
        const insufficientMargin = newCollateral < requiredMargin;
        const orderExceedsLiquidity = tradeParams.sizeDelta > availableLiquidity;
        const maxLeveragedExceeded = newLeverage > maxLeverage;
        const { nextPosition: { long, short }, isSocialized, } = marketSnapshot;
        const isLongImbalanced = long > short;
        const isShortImbalanced = short > long;
        const isAttemptingLongPosition = tradeParams.positionSide === PositionSide.LONG;
        const notPermitted = isSocialized &&
            ((isLongImbalanced && isAttemptingLongPosition) ||
                (isShortImbalanced && !isAttemptingLongPosition));
        // TODO: add more conditions
        const statusCode = getStatusCode({
            orderExceedsLiquidity,
            notPermitted,
            maxLeveragedExceeded,
            insufficientMargin,
        });
        return {
            // TODO: add 1CT gas fee
            fee: toWei6(tradeFee.tradeFee.total),
            liqPrice: toWei6(liquidationPrice),
            margin: toWei6(newCollateral),
            fillPrice: toWei6(estEntryPrice),
            newSize: toWei6(newPosition),
            sizeDelta: toWei6(tradeParams.sizeDelta),
            side: tradeParams.positionSide,
            leverage: toWei6(newLeverage),
            maxLeverage: toWei6(maxLeverage),
            notionalValue: toWei6(calcNotional(tradeParams.sizeDelta, estEntryPrice)),
            status: statusCode,
            showStatus: statusCode !== PotentialTradeStatus.OK,
            statusMessage: getTradeStatusMessage(statusCode, PerpsProvider.PERENNIAL_V2_ARB),
            priceImpact: toWei6(tradeFee.tradeImpact.pct),
            exceedsPriceProtection: toWei6(tradeFee.tradeImpact.perPosition)
                .mul(100)
                .gt(getDefaultPriceImpact(OrderTypeEnum.MARKET)),
        };
    }
    async getConditionalOrders(walletAddress, options) {
        const address = getAddress(walletAddress);
        const openOrders = await this.perennialSdk(options?.chainId).markets.read.openOrders({
            address,
            markets: this.supportedMarkets(options?.chainId),
            skip: options?.skip ?? 0,
            first: options?.first ?? 50,
        });
        if (!openOrders.length) {
            return [];
        }
        return openOrders.map(openOrderToConditionalOrder);
    }
    async getConditionalOrderHistory(walletAddress, options) {
        const orderHistory = await queryOpenOrders({
            walletAddress,
            chainId: options?.chainId || PerennialArbNetworkIds.ARB_MAINNET,
            minTimestamp: options.minTimestamp,
            maxTimestamp: options.maxTimestamp,
            pageSize: options.pageSize,
        });
        const trades = await this.getTradeHistory({
            walletAddress,
            chainId: options.chainId,
        });
        const marketOrders = trades.map((trade) => {
            return {
                id: trade.id,
                asset: trade.asset,
                displayAsset: getDisplayAsset(trade.asset),
                orderId: trade.id,
                createdAt: trade.timestamp,
                updatedAt: trade.timestamp,
                status: 'Filled',
                sizeDelta: trade.sizeDelta.toString(),
                price: trade.fillPrice.toString(),
                side: trade.side,
                conditionalType: OrderTypeEnum.MARKET,
                executed: trade.fillPrice.gt(0) ? trade.fillPrice.toString() : undefined,
                isFullPosition: true, // TODO
                isLiquidation: false, // TODO
                timestamp: Number(trade.timestamp) * 1000,
                txnHash: trade.txnHash,
                orderType: OrderTypeEnum.MARKET,
            };
        });
        return [...orderHistory, ...marketOrders].sort((a, b) => b.timestamp - a.timestamp);
    }
    // Does not include # of trades
    async getDailyVolumes(chainId) {
        const chainMarkets = this.supportedMarkets(chainId);
        const markets = Object.values(chainMarkets).filter(notEmpty);
        const dailyVolumes = await this.perennialSdk(chainId).markets.read.markets24hrData({ markets });
        return Object.entries(dailyVolumes).reduce((volumes, [key, volumeData]) => {
            const volume = toWei6(volumeData.takerVolumes.reduce((acc, data) => acc + BigInt(data.longNotional) + BigInt(data.shortNotional), 0n));
            const asset = assetToFuturesMarketAsset(key);
            volumes[asset] = {
                volume,
                trades: ZERO_WEI,
            };
            if (volumes.ALL) {
                volumes.ALL = {
                    volume: volumes.ALL.volume.add(volume),
                    trades: ZERO_WEI,
                };
            }
            else {
                volumes.ALL = {
                    volume,
                    trades: ZERO_WEI,
                };
            }
            return volumes;
        }, {});
    }
    async getPnlUpdates(walletAddress, chainId) {
        return queryPnlUpdates({
            walletAddress,
            chainId: chainId ?? PerennialArbNetworkIds.ARB_MAINNET,
        });
    }
    // Can be used to update a conditional order via cancel and replace
    async submitConditionalOrder({ marketAddress, walletAddress, order, marketOracles, marketSnapshots, cancelOrder, chainId, onCommitmentError, isOneClickTrade = false, }) {
        const { usdcBalance, allowance } = await this.getUsdcBalanceAndAllowance(walletAddress, chainId);
        if (!marketOracles) {
            marketOracles = await this.perennialSdk(chainId).markets.read.marketOracles();
        }
        if (!marketSnapshots) {
            marketSnapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
                address: walletAddress,
                marketOracles,
            });
        }
        if (!marketSnapshots || !marketSnapshots.user) {
            throw new Error('No market snapshots found'); // TODO: Or handle this some other way
        }
        const requiredMargin = order.marginDelta - (cancelOrder?.margin ?? 0n);
        const { withdrawMarginRequests, totalMarginToWithdraw } = await this.prepareIdleMarginWithdrawals({
            walletAddress,
            marketSnapshots,
            chainId,
            orderMarginDelta: requiredMargin > 0n ? requiredMargin : 0n,
            usdcBalance,
        });
        const availableMargin = usdcBalance + totalMarginToWithdraw + (cancelOrder?.margin ?? 0n);
        if (order.marginDelta > 0n && order.marginDelta > availableMargin) {
            throw new Error('Insufficient balance');
        }
        if (order.marginDelta > 0n && order.marginDelta > allowance) {
            throw new Error('Insufficient USDC allowance');
        }
        const cancelOrderDetails = cancelOrder
            ? { market: cancelOrder.marketAddress, nonce: BigInt(cancelOrder.orderId) }
            : undefined;
        const orderType = formatPerennialOrderType(order.orderType);
        if (orderType === OrderTypes.market)
            throw new Error('Inavlid order type "Market Order"');
        const isBelow = (order.direction === PositionSide.LONG &&
            (order.orderType === OrderTypeEnum.LIMIT ||
                order.orderType === OrderTypeEnum.STOP_LOSS ||
                order.orderType === OrderTypeEnum.STOP)) ||
            (order.direction === PositionSide.SHORT && order.orderType === OrderTypeEnum.TAKE_PROFIT);
        const comparison = isBelow ? TriggerComparison.lte : TriggerComparison.gte;
        const aaFee = isOneClickTrade ? await this.getAvgTxCost() : undefined;
        const params = {
            address: walletAddress,
            marketAddress: marketAddress,
            marketOracles,
            marketSnapshots,
            orderType: orderType,
            delta: order.sizeDelta,
            triggerComparison: comparison,
            collateralDelta: order.marginDelta,
            cancelOrderDetails: cancelOrderDetails ? [cancelOrderDetails] : undefined,
            onCommitmentError,
            side: order.direction === PositionSide.LONG
                ? PerennialPositionSide.long
                : PerennialPositionSide.short,
            ...getConditionalOrderParam(orderType, order.price),
            ...getConditionalOrderFee(orderType, isOneClickTrade, aaFee),
        };
        const orderRequest = await this.perennialSdk(chainId).markets.build.placeOrder(params);
        if (!orderRequest)
            throw new Error('Failed to place order');
        return this.batchTransactions([...withdrawMarginRequests, orderRequest]);
    }
    async cancelConditionalOrder({ walletAddress, marketAddress, orderId, chainId, }) {
        return this.perennialSdk(chainId).markets.build.cancelOrder({
            address: walletAddress,
            orderDetails: [{ market: marketAddress, nonce: BigInt(orderId) }],
        });
    }
    // Requires marketFactoryApproval
    async modifyPosition({ marketAddress, walletAddress, marketOracles, marketSnapshots, onCommitmentError, order, chainId, isOneClickTrade = false, }) {
        const { usdcBalance, allowance } = await this.getUsdcBalanceAndAllowance(walletAddress, chainId);
        if (!marketOracles) {
            marketOracles = await this.perennialSdk(chainId).markets.read.marketOracles();
        }
        if (!marketSnapshots) {
            marketSnapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
                address: walletAddress,
                marketOracles,
            });
        }
        if (!marketSnapshots || !marketSnapshots.user) {
            throw new Error('No market snapshots found'); // TODO: Or handle this some other way
        }
        const asset = addressToAsset(chainId ?? PerennialArbNetworkIds.ARB_MAINNET, marketAddress);
        const userPositionData = marketSnapshots.user[asset];
        const positionAbs = userPositionData.nextMagnitude + order.sizeDelta;
        const { withdrawMarginRequests, totalMarginToWithdraw } = await this.prepareIdleMarginWithdrawals({
            walletAddress,
            marketSnapshots,
            chainId,
            orderMarginDelta: order.marginDelta,
            usdcBalance,
        });
        if (order.marginDelta > 0n && order.marginDelta > usdcBalance + totalMarginToWithdraw) {
            throw new Error('Insufficient balance'); // TODO: Or handle this some other way
        }
        if (order.marginDelta > 0n && order.marginDelta > allowance) {
            throw new Error('Insufficient allowance'); // TODO: Or handle this some other way
        }
        const positionSide = order.direction === PositionSide.LONG
            ? PerennialPositionSide.long
            : PerennialPositionSide.short;
        const aaFee = isOneClickTrade ? await this.getAvgTxCost() : undefined;
        const tradeRequest = await this.perennialSdk(chainId).markets.build.modifyPosition({
            positionSide,
            positionAbs,
            collateralDelta: order.marginDelta,
            interfaceFee: interfaceFee(isOneClickTrade, aaFee),
            address: walletAddress,
            marketSnapshots,
            marketOracles,
            marketAddress: marketAddress,
            takeProfitPrice: order.takeProfit, // Note: SL/TPs will perform a full close if placed on position open.
            stopLossPrice: order.stopLoss,
            onCommitmentError,
        });
        if (!tradeRequest)
            throw new Error('Failed to place order');
        return this.batchTransactions([...withdrawMarginRequests, tradeRequest]);
    }
    async closePosition({ market, walletAddress, marketSnapshots, marketOracles, chainId, isOneClickTrade = false, onCommitmentError, }) {
        if (!marketOracles) {
            marketOracles = await this.perennialSdk(chainId).markets.read.marketOracles();
        }
        if (!marketSnapshots) {
            marketSnapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
                address: walletAddress,
                marketOracles,
            });
        }
        if (!marketSnapshots || !marketSnapshots.user) {
            throw new Error('No market snapshots found'); // TODO: Or handle this some other way
        }
        const asset = addressToAsset(chainId ?? PerennialArbNetworkIds.ARB_MAINNET, market.marketAddress);
        const userPositionData = marketSnapshots.user[asset];
        const currentPositionSize = userPositionData.nextMagnitude;
        if (currentPositionSize === 0n) {
            return;
        }
        return this.perennialSdk(chainId).markets.build.modifyPosition({
            positionSide: userPositionData.nextSide,
            positionAbs: 0n,
            collateralDelta: 0n,
            interfaceFee: interfaceFee(isOneClickTrade),
            address: walletAddress,
            marketSnapshots,
            marketOracles,
            marketAddress: market.marketAddress,
            onCommitmentError,
        });
    }
    async withdrawIdleMargin({ walletAddress, marketOracles, marketSnapshots, withdrawAmount, chainId, }) {
        if (!marketOracles) {
            marketOracles = await this.perennialSdk(chainId).markets.read.marketOracles();
        }
        if (!marketSnapshots) {
            marketSnapshots = await this.perennialSdk(chainId).markets.read.marketSnapshots({
                address: walletAddress,
                marketOracles,
            });
        }
        if (!marketSnapshots || !marketSnapshots.user) {
            throw new Error('No market snapshots found');
        }
        const { withdrawMarginRequests } = await this.prepareIdleMarginWithdrawals({
            walletAddress,
            marketSnapshots,
            chainId,
            orderMarginDelta: withdrawAmount,
            usdcBalance: BigInt(0),
        });
        return this.batchTransactions(withdrawMarginRequests);
    }
    batchTransactions(transactions) {
        return mergeMultiInvokerTxs(transactions);
    }
    async prepareIdleMarginWithdrawals({ marketSnapshots, walletAddress, chainId, orderMarginDelta, usdcBalance, isOneClickTrade, }) {
        const idleMarginInMarkets = await this.getIdleMarginInMarkets(walletAddress, {
            marketSnapshots,
            chainId,
        });
        const totalFreeMargin = fromWei6(idleMarginInMarkets.totalIdleInMarkets) + usdcBalance;
        if (orderMarginDelta > 0n && orderMarginDelta > totalFreeMargin) {
            throw new Error('Insufficient balance');
        }
        const withdrawMarginRequests = [];
        let requiredMargin = orderMarginDelta;
        let totalMarginToWithdraw = 0n;
        if (orderMarginDelta > 0n) {
            for (const asset of Object.keys(idleMarginInMarkets.marketsWithIdleMargin)) {
                const marginWei = idleMarginInMarkets.marketsWithIdleMargin[asset];
                const idleMargin = fromWei6(marginWei);
                if (requiredMargin > 0n) {
                    const market = marketSnapshots.market[futuresMarketAssetToAsset(asset)]
                        ?.marketAddress;
                    if (market) {
                        const request = await this.modifyPosition({
                            marketAddress: market,
                            walletAddress: walletAddress,
                            chainId: chainId,
                            isOneClickTrade,
                            order: {
                                sizeDelta: BigInt(0),
                                marginDelta: -idleMargin,
                                direction: PositionSide.LONG,
                            },
                        });
                        if (request)
                            withdrawMarginRequests.push(request);
                        requiredMargin -= idleMargin;
                        totalMarginToWithdraw += idleMargin;
                    }
                }
            }
        }
        return {
            withdrawMarginRequests,
            totalMarginToWithdraw,
        };
    }
    async getAvgTxCost() {
        try {
            const { data } = await axios.get(`${process.env.NEXT_PUBLIC_SERVICES_PROXY}/alchemy/tx-cost`);
            const cost = data?.data.avgCost;
            if (Number.isNaN(Number.parseFloat(cost)) || !data?.success) {
                return EST_TRADE_TX_COST_USDC.toString();
            }
            return cost;
        }
        catch (_e) {
            return EST_TRADE_TX_COST_USDC.toString();
        }
    }
    reservedMarginForMarket(orders, asset) {
        return orders
            .filter((order) => order.marketKey === assetToMarketKey(asset) && order.marginDelta.gt(0))
            .reduce((acc, order) => acc + fromWei6(order.marginDelta), BigInt(0));
    }
    async getFuturesFees(epochStart, epochEnd, chainId, wallet) {
        return await queryPerennialFuturesFee({
            chainId,
            minTimestamp: epochStart,
            maxTimestamp: epochEnd,
            wallet,
        });
    }
    async checkOperatorApproval(address, operator, chainId) {
        const operatorApproved = await this.perennialSdk(chainId).operator.read.multiInvokerOperatorApproval({
            address,
            operator,
        });
        return operatorApproved;
    }
    async addDelegate(_address, operator, chainId) {
        return this.perennialSdk(chainId).operator.build.approveMultiInvokerOperatorTx({
            enabled: true,
            operator,
        });
    }
    async removeDelegate(_address, operator, chainId) {
        return this.perennialSdk(chainId).operator.build.approveMultiInvokerOperatorTx({
            enabled: false,
            operator,
        });
    }
    async getSubAccountsForAccount(walletAddress, chainId) {
        return await querySubAccountsForAccount(walletAddress, chainId);
    }
    async getDelegatesForAccount(walletAddress, chainId) {
        return await queryDelegatesForAccount(walletAddress, chainId);
    }
    async approveUSDC(chainId, suggestedAmount) {
        return this.perennialSdk(chainId).operator.build.approveUSDC({ suggestedAmount });
    }
    async approveMarketFactory(chainId) {
        return this.perennialSdk(chainId).operator.build.approveMarketFactoryTx();
    }
}
