import { ZERO_WEI } from '@kwenta/sdk/constants'
import {
	type AssetKey,
	FuturesMarketAsset,
	type TokenAllowances,
	type TokenTickers,
	type TokenTickersAndEth,
} from '@kwenta/sdk/types'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { selectProviderNetwork } from 'state/futures/common/selectors'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { selectWallet } from 'state/wallet/selectors'

export const selectBalancesAllProviders = createSelector(
	selectWallet,
	(state: RootState) => state.balances,
	(wallet, balances) => {
		if (!wallet) return {}
		return balances.balancesByChain[wallet] ?? {}
	}
)

export const selectBalances = createSelector(
	selectWallet,
	selectProviderNetwork,
	(state: RootState) => state.balances,
	(wallet, chainId, balances) => {
		if (!wallet) return {}
		const tokenBalances = balances.balancesByChain[wallet]?.[chainId] ?? {}
		return tokenBalances
	}
)

export const selectTokenAllowances = createSelector(
	selectWallet,
	selectProviderNetwork,
	(state: RootState) => state.balances,
	(wallet, chainId, balances) => {
		if (!wallet) return {}
		const tokenBalances = balances.balancesByChain[wallet]?.[chainId] ?? {}
		return Object.entries(tokenBalances).reduce<Partial<Record<TokenTickers, TokenAllowances>>>(
			(acc, [asset, balanceAndAllowances]) => {
				const serialized = Object.entries(balanceAndAllowances.allowances).reduce<TokenAllowances>(
					(acc, [spender, value]) => {
						if (value) acc[spender] = wei(value)
						return acc
					},
					{}
				)
				acc[asset as TokenTickers] = serialized
				return acc
			},
			{}
		)
	}
)

export const selectEthWalletBalance = createSelector(selectBalances, (balances) =>
	wei(balances.ETH?.balance ?? 0)
)

export const selectV3DepositableBalances = createSelector(selectBalances, (tokenBalances) => {
	return {
		USDC: wei(tokenBalances.USDC?.balance ?? 0),
		WETH: wei(tokenBalances.WETH?.balance ?? 0),
		USDx: wei(tokenBalances.USDx?.balance ?? 0),
		ETH: wei(tokenBalances.ETH?.balance ?? 0),
		tBTC: wei(tokenBalances.tBTC?.balance ?? 0),
		sBTC: wei(tokenBalances.sBTC?.balance ?? 0),
		USDe: wei(tokenBalances.USDe?.balance ?? 0),
		wSOL: wei(tokenBalances.wSOL?.balance ?? 0),
	}
})

const convertTokenTickerToAssetKey = (asset: TokenTickersAndEth): AssetKey => {
	const isFuturesMarketAsset = Object.values(FuturesMarketAsset).includes(
		asset as FuturesMarketAsset
	)

	if (isFuturesMarketAsset) {
		return asset as FuturesMarketAsset
	}
	switch (asset) {
		case 'SUSD':
		case 'sUSDe':
		case 'sUSDC':
		case 'USDe':
		case 'USDx':
		case 'USDC':
		case 'DAI':
		case 'USDT':
			return FuturesMarketAsset.USDT
		case 'WETH':
		case 'sETH':
		case 'ETH':
			return FuturesMarketAsset.sETH
		case 'sBTC':
		case 'tBTC':
			return FuturesMarketAsset.sBTC
		case 'swSOL':
		case 'wSOL':
			return FuturesMarketAsset.SOL
		default:
			throw new Error(`Unknown asset: ${asset}`)
	}
}

export const selectV3DepositableBalancesInUSD = createSelector(
	selectV3DepositableBalances,
	selectOffchainPricesInfo,
	(balances, prices) => {
		return Object.entries(balances).reduce(
			(acc, [asset, balance]) => {
				const price = prices[convertTokenTickerToAssetKey(asset as TokenTickersAndEth)]?.price
				acc[asset as TokenTickers] = balance.mul(price ?? ZERO_WEI)
				return acc
			},
			{} as Record<TokenTickers, Wei>
		)
	}
)

export const selectUsdcBalance = createSelector(
	selectV3DepositableBalances,
	(balances) => balances.USDC ?? wei('0')
)
