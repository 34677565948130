import BatchClaimerABI from './abis/BatchClaimer.js';
import BoostNftABI from './abis/BoostNFT.js';
import CoreProxyABI from './abis/CoreProxy.js';
import DappMaintenanceABI from './abis/DappMaintenance.js';
import ERC20ABI from './abis/ERC20.js';
import EscrowMigratorABI from './abis/EscrowMigrator.js';
import FeeReimbursementAppABI from './abis/FeeReimbursementApp.js';
import FeeReimbursementClaimABI from './abis/FeeReimbursementClaim.js';
import GnosisSafeL2ProxyABI from './abis/GnosisSafeL2Proxy.js';
import KwentaArrakisVaultABI from './abis/KwentaArrakisVault.js';
import KwentaStakingRewardsABI from './abis/KwentaStakingRewards.js';
import KwentaStakingRewardsV2ABI from './abis/KwentaStakingRewardsV2.js';
import MarginEngineABI from './abis/MarginEngine.js';
import MarginEngineMultiCollateralABI from './abis/MarginEngineMultiCollateral.js';
import MulticallABI from './abis/Multicall3_1.js';
import MultipleMerkleDistributorArbABI from './abis/MultipleMerkleDistributorArb.js';
import MultipleMerkleDistributorOpABI from './abis/MultipleMerkleDistributorOp.js';
import MultipleMerkleDistributorPerpsV2ABI from './abis/MultipleMerkleDistributorPerpsV2.js';
import Permit2ABI from './abis/Permit2.js';
import PerpsV2MarketDataABI from './abis/PerpsV2MarketData';
import PerpsV2MarketSettingsABI from './abis/PerpsV2MarketSettings';
import PerpsV3AccountProxyABI from './abis/PerpsV3AccountProxy.js';
import PerpsV3MarketProxyABI from './abis/PerpsV3MarketProxy';
import PythABI from './abis/Pyth.js';
import RewardEscrowABI from './abis/RewardEscrow.js';
import RewardEscrowV2ABI from './abis/RewardEscrowV2.js';
import SmartMarginAccountFactoryAbi from './abis/SmartMarginAccountFactory.js';
import SpotV3MarketProxy from './abis/SpotV3MarketProxy';
import StakingRewardsABI from './abis/StakingRewards.js';
import SupplyScheduleABI from './abis/SupplySchedule.js';
import SynthUtilABI from './abis/SynthUtil.js';
import SynthetixABI from './abis/Synthetix.js';
import SynthetixV3ProxyABI from './abis/SynthetixV3Proxy.js';
import SystemStatusABI from './abis/SystemStatus.js';
import vKwentaRedeemerABI from './abis/vKwentaRedeemer.js';
import veKwentaRedeemerABI from './abis/veKwentaRedeemer.js';
import { COMMON_ADDRESSES, KWENTA_ADDRESSES, SNX_V2_PERPS_ADDRESSES, SNX_V3_PERPS_ADDRESSES, VIP, } from './constants.js';
export const snxV2ContractsByNetwork = (networkId) => {
    return {
        PerpsV2MarketData: {
            address: SNX_V2_PERPS_ADDRESSES.PerpsV2MarketData[networkId],
            abi: PerpsV2MarketDataABI,
        },
        PerpsV2MarketSettings: {
            address: SNX_V2_PERPS_ADDRESSES.PerpsV2MarketSettings[networkId],
            abi: PerpsV2MarketSettingsABI,
        },
        SmartMarginAccountFactory: {
            address: SNX_V2_PERPS_ADDRESSES.SmartMarginAccountFactory[networkId],
            abi: SmartMarginAccountFactoryAbi,
        },
        FeeReimbursementClaim: {
            address: VIP.FEE_REIMBURSEMENT_CLAIM_ADDRESSES[networkId],
            abi: FeeReimbursementClaimABI,
        },
        FeeReimbursementApp: {
            address: VIP.FEE_REIMBURSEMENT_APP_ADDRESSES[networkId],
            abi: FeeReimbursementAppABI,
        },
    };
};
export const snxV3ContractsByNetwork = (networkId) => {
    return {
        PerpsV3MarketProxy: {
            address: SNX_V3_PERPS_ADDRESSES.PerpsV3MarketProxy[networkId],
            abi: PerpsV3MarketProxyABI,
        },
        PerpsV3AccountProxy: {
            address: SNX_V3_PERPS_ADDRESSES.PerpsV3AccountProxy[networkId],
            abi: PerpsV3AccountProxyABI,
        },
        SpotV3MarketProxy: {
            address: SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[networkId],
            abi: SpotV3MarketProxy,
        },
        SynthetixV3Proxy: {
            address: SNX_V3_PERPS_ADDRESSES.SynthetixV3Proxy[networkId],
            abi: SynthetixV3ProxyABI,
        },
        CoreProxy: {
            address: SNX_V3_PERPS_ADDRESSES.CoreProxy[networkId],
            abi: CoreProxyABI,
        },
        MarginEngine: SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
            ? {
                address: SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId],
                abi: MarginEngineABI,
            }
            : undefined,
        MarginEngineMultiCollateral: SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
            ? {
                address: SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId],
                abi: MarginEngineMultiCollateralABI,
            }
            : undefined,
    };
};
export const kwentaContractsByNetwork = (networkId) => {
    if (networkId === 42161) {
        return {
            MultipleMerkleDistributorArb: KWENTA_ADDRESSES.ArbRewards[networkId]
                ? {
                    address: KWENTA_ADDRESSES.ArbRewards[networkId],
                    abi: MultipleMerkleDistributorArbABI,
                }
                : undefined,
        };
    }
    else if (networkId === 10) {
        return {
            KwentaArrakisVault: KWENTA_ADDRESSES.KwentaArrakisVault[networkId]
                ? {
                    address: KWENTA_ADDRESSES.KwentaArrakisVault[networkId],
                    abi: KwentaArrakisVaultABI,
                }
                : undefined,
            StakingRewards: KWENTA_ADDRESSES.StakingRewards[networkId]
                ? {
                    address: KWENTA_ADDRESSES.StakingRewards[networkId],
                    abi: StakingRewardsABI,
                }
                : undefined,
            RewardEscrow: KWENTA_ADDRESSES.RewardEscrow[networkId]
                ? {
                    address: KWENTA_ADDRESSES.RewardEscrow[networkId],
                    abi: RewardEscrowABI,
                }
                : undefined,
            KwentaToken: KWENTA_ADDRESSES.KwentaToken[networkId]
                ? {
                    address: KWENTA_ADDRESSES.KwentaToken[networkId],
                    abi: ERC20ABI,
                }
                : undefined,
            SupplySchedule: KWENTA_ADDRESSES.SupplySchedule[networkId]
                ? {
                    address: KWENTA_ADDRESSES.SupplySchedule[networkId],
                    abi: SupplyScheduleABI,
                }
                : undefined,
            vKwentaToken: KWENTA_ADDRESSES.vKwentaToken[networkId]
                ? {
                    address: KWENTA_ADDRESSES.vKwentaToken[networkId],
                    abi: ERC20ABI,
                }
                : undefined,
            MultipleMerkleDistributorPerpsV2: KWENTA_ADDRESSES.TradingRewardsPerpsV2[networkId]
                ? {
                    address: KWENTA_ADDRESSES.TradingRewardsPerpsV2[networkId],
                    abi: MultipleMerkleDistributorPerpsV2ABI,
                }
                : undefined,
            MultipleMerkleDistributorStakingV2: KWENTA_ADDRESSES.TradingRewardsStakingV2[networkId]
                ? {
                    address: KWENTA_ADDRESSES.TradingRewardsStakingV2[networkId],
                    abi: MultipleMerkleDistributorPerpsV2ABI,
                }
                : undefined,
            MultipleMerkleDistributorOp: KWENTA_ADDRESSES.OpRewards[networkId]
                ? {
                    address: KWENTA_ADDRESSES.OpRewards[networkId],
                    abi: MultipleMerkleDistributorOpABI,
                }
                : undefined,
            MultipleMerkleDistributorSnxOp: KWENTA_ADDRESSES.SnxOpRewards[networkId]
                ? {
                    address: KWENTA_ADDRESSES.SnxOpRewards[networkId],
                    abi: MultipleMerkleDistributorOpABI,
                }
                : undefined,
            MultipleMerkleDistributorOpReferral: KWENTA_ADDRESSES.OpReferralRewards[networkId]
                ? {
                    address: KWENTA_ADDRESSES.OpReferralRewards[networkId],
                    abi: MultipleMerkleDistributorOpABI,
                }
                : undefined,
            BatchClaimer: KWENTA_ADDRESSES.BatchClaimer[networkId]
                ? {
                    address: KWENTA_ADDRESSES.BatchClaimer[networkId],
                    abi: BatchClaimerABI,
                }
                : undefined,
            veKwentaToken: KWENTA_ADDRESSES.veKwentaToken[networkId]
                ? {
                    address: KWENTA_ADDRESSES.veKwentaToken[networkId],
                    abi: ERC20ABI,
                }
                : undefined,
            KwentaStakingRewards: KWENTA_ADDRESSES.KwentaStakingRewards[networkId]
                ? {
                    address: KWENTA_ADDRESSES.KwentaStakingRewards[networkId],
                    abi: KwentaStakingRewardsABI,
                }
                : undefined,
            vKwentaRedeemer: KWENTA_ADDRESSES.vKwentaRedeemer[networkId]
                ? {
                    address: KWENTA_ADDRESSES.vKwentaRedeemer[networkId],
                    abi: vKwentaRedeemerABI,
                }
                : undefined,
            veKwentaRedeemer: KWENTA_ADDRESSES.veKwentaRedeemer[networkId]
                ? {
                    address: KWENTA_ADDRESSES.veKwentaRedeemer[networkId],
                    abi: veKwentaRedeemerABI,
                }
                : undefined,
            KwentaStakingRewardsV2: KWENTA_ADDRESSES.KwentaStakingRewardsV2[networkId]
                ? {
                    address: KWENTA_ADDRESSES.KwentaStakingRewardsV2[networkId],
                    abi: KwentaStakingRewardsV2ABI,
                }
                : undefined,
            RewardEscrowV2: KWENTA_ADDRESSES.RewardEscrowV2[networkId]
                ? {
                    address: KWENTA_ADDRESSES.RewardEscrowV2[networkId],
                    abi: RewardEscrowV2ABI,
                }
                : undefined,
            EscrowMigrator: KWENTA_ADDRESSES.EscrowMigrator[networkId]
                ? {
                    address: KWENTA_ADDRESSES.EscrowMigrator[networkId],
                    abi: EscrowMigratorABI,
                }
                : undefined,
            BoostNft: KWENTA_ADDRESSES.BoostNft[networkId]
                ? {
                    address: KWENTA_ADDRESSES.BoostNft[networkId],
                    abi: BoostNftABI,
                }
                : undefined,
        };
    }
    else {
        return {};
    }
};
export const commonContractsByNetwork = (networkId) => {
    return {
        SystemStatus: COMMON_ADDRESSES.SystemStatus[networkId]
            ? {
                address: COMMON_ADDRESSES.SystemStatus[networkId],
                abi: SystemStatusABI,
            }
            : undefined,
        SynthUtil: COMMON_ADDRESSES.SynthUtil[networkId]
            ? {
                address: COMMON_ADDRESSES.SynthUtil[networkId],
                abi: SynthUtilABI,
            }
            : undefined,
        Pyth: COMMON_ADDRESSES.Pyth[networkId]
            ? {
                address: COMMON_ADDRESSES.Pyth[networkId],
                abi: PythABI,
            }
            : undefined,
        Synthetix: COMMON_ADDRESSES.Synthetix[networkId]
            ? {
                address: COMMON_ADDRESSES.Synthetix[networkId],
                abi: SynthetixABI,
            }
            : undefined,
        DappMaintenance: COMMON_ADDRESSES.DappMaintenance[networkId]
            ? {
                address: COMMON_ADDRESSES.DappMaintenance[networkId],
                abi: DappMaintenanceABI,
            }
            : undefined,
        MultiCall3_1: COMMON_ADDRESSES.Multicall3_1[networkId]
            ? {
                address: COMMON_ADDRESSES.Multicall3_1[networkId],
                abi: MulticallABI,
            }
            : undefined,
        Permit2: COMMON_ADDRESSES.Permit2[networkId]
            ? {
                address: COMMON_ADDRESSES.Permit2[networkId],
                abi: Permit2ABI,
            }
            : undefined,
        GnosisSafeL2: COMMON_ADDRESSES.GnosisSafeL2[networkId]
            ? {
                address: COMMON_ADDRESSES.GnosisSafeL2[networkId],
                abi: GnosisSafeL2ProxyABI,
            }
            : undefined,
    };
};
export const tokenContractsByNetwork = (networkId) => {
    return {
        SUSD: COMMON_ADDRESSES.SUSD[networkId]
            ? {
                address: COMMON_ADDRESSES.SUSD[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        USDC: COMMON_ADDRESSES.USDC[networkId]
            ? {
                address: COMMON_ADDRESSES.USDC[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        USDT: COMMON_ADDRESSES.USDT[networkId]
            ? {
                address: COMMON_ADDRESSES.USDT[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        DAI: COMMON_ADDRESSES.DAI[networkId]
            ? {
                address: COMMON_ADDRESSES.DAI[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        LUSD: COMMON_ADDRESSES.LUSD[networkId]
            ? {
                address: COMMON_ADDRESSES.LUSD[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        WETH: COMMON_ADDRESSES.WETH[networkId]
            ? {
                address: COMMON_ADDRESSES.WETH[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        tBTC: COMMON_ADDRESSES.tBTC[networkId]
            ? {
                address: COMMON_ADDRESSES.tBTC[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        sUSDC: COMMON_ADDRESSES.sUSDC[networkId]
            ? {
                address: COMMON_ADDRESSES.sUSDC[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        USDx: COMMON_ADDRESSES.USDx[networkId]
            ? {
                address: COMMON_ADDRESSES.USDx[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        sETH: COMMON_ADDRESSES.sETH[networkId]
            ? {
                address: COMMON_ADDRESSES.sETH[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        sBTC: COMMON_ADDRESSES.sBTC[networkId]
            ? {
                address: COMMON_ADDRESSES.sBTC[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        USDe: COMMON_ADDRESSES.USDe[networkId]
            ? {
                address: COMMON_ADDRESSES.USDe[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        sUSDe: COMMON_ADDRESSES.sUSDe[networkId]
            ? {
                address: COMMON_ADDRESSES.sUSDe[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        wSOL: COMMON_ADDRESSES.wSOL[networkId]
            ? {
                address: COMMON_ADDRESSES.wSOL[networkId],
                abi: ERC20ABI,
            }
            : undefined,
        swSOL: COMMON_ADDRESSES.swSOL[networkId]
            ? {
                address: COMMON_ADDRESSES.swSOL[networkId],
                abi: ERC20ABI,
            }
            : undefined,
    };
};
export const allContractsByNetwork = (networkId) => {
    return {
        snxV3: snxV3ContractsByNetwork(networkId),
        snxV2: snxV2ContractsByNetwork(networkId),
        kwenta: kwentaContractsByNetwork(networkId),
        common: commonContractsByNetwork(networkId),
        tokens: tokenContractsByNetwork(networkId),
    };
};
