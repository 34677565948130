const abi = [
    {
        inputs: [
            {
                internalType: 'address payable',
                name: '_proxy',
                type: 'address',
            },
            {
                internalType: 'contract TokenState',
                name: '_tokenState',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_totalSupply',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: '_resolver',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'snxRedeemed',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amountLiquidated',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'liquidator',
                type: 'address',
            },
        ],
        name: 'AccountLiquidated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'fromCurrencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'fromAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'toCurrencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'toAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'toAddress',
                type: 'address',
            },
        ],
        name: 'AtomicSynthExchange',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'name',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'destination',
                type: 'address',
            },
        ],
        name: 'CacheUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'ExchangeRebate',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'ExchangeReclaim',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'toCurrencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'toAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'fee',
                type: 'uint256',
            },
        ],
        name: 'ExchangeTracking',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'oldOwner',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnerChanged',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnerNominated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'proxyAddress',
                type: 'address',
            },
        ],
        name: 'ProxyUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'fromCurrencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'fromAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'toCurrencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'toAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'toAddress',
                type: 'address',
            },
        ],
        name: 'SynthExchange',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'newTokenState',
                type: 'address',
            },
        ],
        name: 'TokenStateUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Transfer',
        type: 'event',
    },
    {
        constant: true,
        inputs: [],
        name: 'CONTRACT_NAME',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'DECIMALS',
        outputs: [
            {
                internalType: 'uint8',
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'TOKEN_NAME',
        outputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'TOKEN_SYMBOL',
        outputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'acceptOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
        ],
        name: 'allowance',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'anySynthOrSNXRateIsInvalid',
        outputs: [
            {
                internalType: 'bool',
                name: 'anyRateInvalid',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'availableCurrencyKeys',
        outputs: [
            {
                internalType: 'bytes32[]',
                name: '',
                type: 'bytes32[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'availableSynthCount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
            },
        ],
        name: 'availableSynths',
        outputs: [
            {
                internalType: 'contract ISynth',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'burnSecondary',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'burnSynths',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'burnForAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'burnSynthsOnBehalf',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'burnSynthsToTarget',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'burnForAddress',
                type: 'address',
            },
        ],
        name: 'burnSynthsToTargetOnBehalf',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'collateral',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: '_issuer',
                type: 'address',
            },
        ],
        name: 'collateralisationRatio',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'debtBalanceOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [
            {
                internalType: 'uint8',
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'fromCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'fromAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'toCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'toAmount',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'toAddress',
                type: 'address',
            },
        ],
        name: 'emitAtomicSynthExchange',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'emitExchangeRebate',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'emitExchangeReclaim',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
            {
                internalType: 'bytes32',
                name: 'toCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'toAmount',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'fee',
                type: 'uint256',
            },
        ],
        name: 'emitExchangeTracking',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'fromCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'fromAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'toCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'toAmount',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'toAddress',
                type: 'address',
            },
        ],
        name: 'emitSynthExchange',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
        ],
        name: 'exchange',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'minAmount',
                type: 'uint256',
            },
        ],
        name: 'exchangeAtomically',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'exchangeForAddress',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
        ],
        name: 'exchangeOnBehalf',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'exchangeForAddress',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'address',
                name: 'rewardAddress',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
        ],
        name: 'exchangeOnBehalfWithTracking',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'address',
                name: 'rewardAddress',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
        ],
        name: 'exchangeWithTracking',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'address',
                name: 'rewardAddress',
                type: 'address',
            },
            {
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
        ],
        name: 'exchangeWithTrackingForInitiator',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'bytes32',
                name: 'trackingCode',
                type: 'bytes32',
            },
        ],
        name: 'exchangeWithVirtual',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amountReceived',
                type: 'uint256',
            },
            {
                internalType: 'contract IVirtualSynth',
                name: 'vSynth',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'getFirstNonZeroEscrowIndex',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'isResolverCached',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'isWaitingPeriod',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'issueMaxSynths',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'issueForAddress',
                type: 'address',
            },
        ],
        name: 'issueMaxSynthsOnBehalf',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'issueSynths',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'issueForAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'issueSynthsOnBehalf',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'liquidateDelinquentAccount',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'escrowStartIndex',
                type: 'uint256',
            },
        ],
        name: 'liquidateDelinquentAccountEscrowIndex',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'liquidateSelf',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'maxIssuableSynths',
        outputs: [
            {
                internalType: 'uint256',
                name: 'maxIssuable',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'messageSender',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'migrateEscrowBalanceToRewardEscrowV2',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'migrateEscrowContractBalance',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'mint',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'mintSecondary',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'mintSecondaryRewards',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'nominateNewOwner',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'nominatedOwner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'proxy',
        outputs: [
            {
                internalType: 'contract Proxy',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'rebuildCache',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'remainingIssuableSynths',
        outputs: [
            {
                internalType: 'uint256',
                name: 'maxIssuable',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'alreadyIssued',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'totalSystemDebt',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'resolver',
        outputs: [
            {
                internalType: 'contract AddressResolver',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'resolverAddressesRequired',
        outputs: [
            {
                internalType: 'bytes32[]',
                name: 'addresses',
                type: 'bytes32[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'sUSD',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
        ],
        name: 'setMessageSender',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address payable',
                name: '_proxy',
                type: 'address',
            },
        ],
        name: 'setProxy',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'contract TokenState',
                name: '_tokenState',
                type: 'address',
            },
        ],
        name: 'setTokenState',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'settle',
        outputs: [
            {
                internalType: 'uint256',
                name: 'reclaimed',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'refunded',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'numEntriesSettled',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'synths',
        outputs: [
            {
                internalType: 'contract ISynth',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'synthAddress',
                type: 'address',
            },
        ],
        name: 'synthsByAddress',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'tokenState',
        outputs: [
            {
                internalType: 'contract TokenState',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'totalIssuedSynths',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'totalIssuedSynthsExcludeOtherCollateral',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'transfer',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'transferFrom',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'transferableSynthetix',
        outputs: [
            {
                internalType: 'uint256',
                name: 'transferable',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
];
export default abi;
