type Listener<T> = (instance: T) => void

class Notifier<T> {
	private listeners: Array<Listener<T>> = []

	protected notifyAll() {
		const newInstance = Object.create(Object.getPrototypeOf(this))
		Object.assign(newInstance, this)
		for (const listener of this.listeners) {
			listener(newInstance)
		}
	}

	public addListener(callback: Listener<T>) {
		this.listeners.push(callback)
	}

	public removeListener(callback: Listener<T>) {
		this.listeners = this.listeners.filter((listener) => listener !== callback)
	}
}

export default Notifier

export type { Listener }
