import { wei } from '@kwenta/wei'

import { NO_VALUE } from 'constants/placeholder'

export const INITIAL_TRADER_PROFILE = {
	pnlWithFeesPaid: wei(0),
	liquidations: 0,
	totalTrades: 0,
	totalVolume: wei(0),
	traderShort: '',
	traderEns: null,
	accountOwner: NO_VALUE,
	rank: -1,
	trader: '',
	rankText: NO_VALUE,
	tier: NO_VALUE,
	accountId: '',
}
