export var ConditionalOrderStatusV3;
(function (ConditionalOrderStatusV3) {
    ConditionalOrderStatusV3["Pending"] = "Pending";
    ConditionalOrderStatusV3["Executed"] = "Executed";
    ConditionalOrderStatusV3["Cancelled"] = "Cancelled";
})(ConditionalOrderStatusV3 || (ConditionalOrderStatusV3 = {}));
export var RawCondition;
(function (RawCondition) {
    RawCondition["IsTimestampAfter"] = "isTimestampAfter";
    RawCondition["IsTimestampBefore"] = "isTimestampBefore";
    RawCondition["IsPriceAbove"] = "isPriceAbove";
    RawCondition["IsPriceBelow"] = "isPriceBelow";
    RawCondition["IsPositionSizeAbove"] = "isPositionSizeAbove";
    RawCondition["IsPositionSizeBelow"] = "isPositionSizeBelow";
    RawCondition["IsMarketOpen"] = "isMarketOpen";
    RawCondition["IsOrderFeeBelow"] = "isOrderFeeBelow";
})(RawCondition || (RawCondition = {}));
