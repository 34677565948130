const abi = [
    {
        type: 'function',
        name: 'getMarketCollateralAmount',
        stateMutability: 'view',
        inputs: [
            {
                type: 'uint128',
                name: 'marketId',
            },
            {
                type: 'address',
                name: 'collateralType',
            },
        ],
        outputs: [
            {
                type: 'uint256',
                name: 'collateralAmountD18',
            },
        ],
    },
];
export default abi;
