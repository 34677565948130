const abi = [
    {
        inputs: [
            {
                internalType: 'contract IMultipleMerkleDistributor[]',
                name: '_distributors',
                type: 'address[]',
            },
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'index',
                        type: 'uint256',
                    },
                    {
                        internalType: 'address',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'amount',
                        type: 'uint256',
                    },
                    {
                        internalType: 'bytes32[]',
                        name: 'merkleProof',
                        type: 'bytes32[]',
                    },
                    {
                        internalType: 'uint256',
                        name: 'epoch',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct IMultipleMerkleDistributor.Claims[][]',
                name: '_claims',
                type: 'tuple[][]',
            },
        ],
        name: 'claimMultiple',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export default abi;
