const abi = [
    { inputs: [], stateMutability: 'payable', type: 'constructor' },
    { inputs: [], name: 'ArrayLengthsMismatch', type: 'error' },
    { inputs: [], name: 'Reentrancy', type: 'error' },
    {
        inputs: [
            { internalType: 'bytes[]', name: 'data', type: 'bytes[]' },
            { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
        ],
        name: 'aggregateWithSender',
        outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
        stateMutability: 'payable',
        type: 'function',
    },
    { stateMutability: 'payable', type: 'receive' },
];
export default abi;
