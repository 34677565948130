import type { NetworkId } from '@kwenta/sdk/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useAccountAbstractionConnect from 'services/biconomy/useAccountAbstractionConnect'
import { createContainer } from 'unstated-next'
import {
	useAccount,
	useAccountEffect,
	useDisconnect,
	usePublicClient,
	useSwitchChain,
	useWalletClient,
} from 'wagmi'

import { DEFAULT_CHAIN } from 'constants/network'
import { selectAccountContext, selectShowTestnets } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress, setWalletClient } from 'state/wallet/actions'
import { disconnect, setWalletNetworkId } from 'state/wallet/reducer'
import { selectIsWatcherMode, selectWatcherWallet } from 'state/wallet/selectors'

import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useAlchemySmartAccount } from 'hooks/useAlchemySmartAccount'
import { SOCIAL_LOGIN_ENABLED } from 'sections/accounts/constants'
import { setOpenModal } from 'state/app/reducer'
import type { Chain } from 'wagmi/chains'
import { generateExplorerFunctions, getBaseUrl } from './blockExplorer'
import { wagmiConfig, wagmiConfigWithTestnet } from './config'

export let blockExplorer = generateExplorerFunctions(getBaseUrl(DEFAULT_CHAIN.id as NetworkId))

const useConnector = () => {
	// General
	const dispatch = useAppDispatch()
	const [providerReady, setProviderReady] = useState(false)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const watcherWallet = useAppSelector(selectWatcherWallet)
	const chainWithTestnet = useAppSelector(selectShowTestnets)
	const { network: providerChainId } = useAppSelector(selectAccountContext)
	const config = useMemo(
		() => (chainWithTestnet ? wagmiConfigWithTestnet : wagmiConfig),
		[chainWithTestnet]
	)

	useEffect(() => {
		if (providerChainId) {
			setProviderReady(true)
			blockExplorer = generateExplorerFunctions(getBaseUrl(providerChainId))
		}
	}, [providerChainId])

	// EOA
	const { isConnected: isEoaConnected, chain: eoaChain, address: eoaAddress } = useAccount()
	const { data: eoaWalletClient } = useWalletClient({ config })
	const { openConnectModal } = useConnectModal()
	const { disconnect: eoaDisconnect } = useDisconnect()
	const { switchChain } = useSwitchChain()
	const eoaPublicClient = usePublicClient({ config })
	useAccountAbstractionConnect({ walletClient: eoaWalletClient })

	useEffect(() => {
		if (!isWatcherMode && eoaWalletClient) {
			dispatch(setWalletClient(eoaWalletClient))
		}
	}, [dispatch, isWatcherMode, eoaWalletClient])

	useEffect(() => {
		if (isEoaConnected && eoaChain) {
			dispatch(resetWalletAddress({ address: eoaAddress, selectedType: 'signer' }))
			dispatch(setWalletNetworkId(eoaChain.id as NetworkId))
		}
	}, [dispatch, eoaAddress, eoaChain, isEoaConnected])

	// AA
	const {
		isConnected: isAaConnected,
		chain: aaChain,
		alchemySigner,
		isAaActive,
		setChain,
	} = useAlchemySmartAccount()

	// wallet connected status and chain info
	const isWalletConnected = isEoaConnected || isAaConnected || (isWatcherMode && !!watcherWallet)
	const activeChain = isWalletConnected ? eoaChain || aaChain : DEFAULT_CHAIN

	// Connect / Disconnect wallet on account change
	useAccountEffect({
		onDisconnect: () => dispatch(disconnect()),
	})

	// Wallet Actions
	const login = useCallback(() => {
		if (SOCIAL_LOGIN_ENABLED) {
			dispatch(setOpenModal('sign_in'))
		} else {
			openConnectModal?.()
		}
	}, [dispatch, openConnectModal])

	const logout = useCallback(() => {
		if (isAaActive && alchemySigner) {
			alchemySigner.disconnect()
			dispatch(disconnect())
		} else {
			eoaDisconnect()
		}
	}, [eoaDisconnect, isAaActive, alchemySigner, dispatch])

	const switchToChain = useCallback(
		async (network: Chain) => {
			return isAaActive ? setChain({ chain: network }) : switchChain({ chainId: network.id })
		},
		[isAaActive, setChain, switchChain]
	)

	return {
		network: activeChain,
		isWalletConnected,
		client: eoaPublicClient,
		walletClient: eoaWalletClient,
		providerReady,
		alchemySigner,
		isAaActive,
		login,
		logout,
		switchToChain,
	}
}

const Connector = createContainer(useConnector)

export default Connector
