import { type Leaderboard, PerpsProvider, SnxV3NetworkIds } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { setQueryStatus } from 'state/futures/reducer'
import { FetchStatus, type ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import { setLeaderboardData } from './reducer'
import { selectValidSearchTerm } from './selectors'

export const fetchLeaderboard = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'stats/fetchLeaderboard',
	async (providers, { getState, dispatch, extra: { sdk } }) => {
		dispatch(setQueryStatus({ key: 'leaderboard', status: FetchStatus.Loading }))
		const searchTerm = selectValidSearchTerm(getState())
		const wallet = selectWallet(getState())

		let leaderboard: Leaderboard = {
			wallet: [],
			search: [],
			all: [],
		}

		for (const provider of providers) {
			try {
				if (provider === PerpsProvider.SNX_V2_OP) {
					leaderboard = await sdk.stats.getSnxV2Leaderboard(searchTerm || '', wallet || '')
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					leaderboard = await sdk.stats.getPerennialV2Leaderboard(searchTerm || '', wallet || '')
				} else if (provider === PerpsProvider.SNX_V3_BASE) {
					leaderboard = await sdk.stats.getV3Leaderboard(
						SnxV3NetworkIds.BASE_MAINNET,
						searchTerm || '',
						wallet || ''
					)
				} else if (provider === PerpsProvider.SNX_V3_ARB) {
					leaderboard = await sdk.stats.getV3Leaderboard(
						SnxV3NetworkIds.ARB_MAINNET,
						searchTerm || '',
						wallet || ''
					)
				}

				dispatch(
					setLeaderboardData({
						provider,
						leaderboard,
					})
				)
			} catch (error) {
				logError(error)
			}
		}
		dispatch(setQueryStatus({ key: 'leaderboard', status: FetchStatus.Success }))
	}
)
