import type { NetworkId } from '@kwenta/sdk/types'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import sdk from 'state/sdk'

import type { SetWalletPayload, WalletState } from './types'

export const WALLET_INITIAL_STATE: WalletState = {
	walletAddress: {
		signerWallet: undefined,
		watcherWallet: undefined,
		selectedType: 'signer',
	},
	abstractionAddress: undefined,
	walletNetworkId: undefined,
	emailAddress: undefined,
}

const walletSlice = createSlice({
	name: 'wallet',
	initialState: WALLET_INITIAL_STATE,
	reducers: {
		setWalletAddress: (state, action: PayloadAction<SetWalletPayload>) => {
			if (action.payload.selectedType === 'signer') {
				state.walletAddress.signerWallet = action.payload.address
				state.walletAddress.watcherWallet = undefined
				state.walletAddress.subAccountWallet = undefined
				!!action.payload.address && sdk.setWalletAddress(action.payload.address)
			} else if (action.payload.selectedType === 'watcher') {
				state.walletAddress.watcherWallet = action.payload.address
				state.walletAddress.subAccountWallet = undefined
				!!action.payload.address && sdk.setWalletAddress(action.payload.address)
			} else if (action.payload.selectedType === 'sub_account') {
				state.walletAddress.watcherWallet = undefined
				state.walletAddress.subAccountWallet = action.payload.address
			}
			state.walletAddress.selectedType = action.payload.selectedType
		},
		setWalletNetworkId: (state, action: PayloadAction<number | undefined>) => {
			state.walletNetworkId = action.payload as NetworkId | undefined
		},
		setAbstractionAddress: (state, action) => {
			state.abstractionAddress = action.payload
		},
		setEmailAddress(state, action) {
			state.emailAddress = action.payload
		},
		disconnect: (state) => {
			state.walletAddress.selectedType = 'signer'
			state.walletAddress.signerWallet = undefined
			state.walletAddress.watcherWallet = undefined
			state.walletAddress.subAccountWallet = undefined
			state.abstractionAddress = undefined
			state.emailAddress = undefined
		},
	},
})

export const {
	setWalletAddress,
	setAbstractionAddress,
	setWalletNetworkId,
	setEmailAddress,
	disconnect,
} = walletSlice.actions

export default walletSlice.reducer
