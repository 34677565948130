import { DEFAULT_LEADERBOARD_DATA } from '@kwenta/sdk/constants'
import { type Leaderboard, PerpsProvider } from '@kwenta/sdk/types'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { StatsTimeframe } from 'hooks/useStatsData'
import { DEFAULT_QUERY_STATUS, LOADING_STATUS, SUCCESS_STATUS } from 'state/constants'
import { FetchStatus } from 'state/types'

import { fetchLeaderboard } from './actions'
import type { StatsState } from './types'

export const STATS_INITIAL_STATE: StatsState = {
	queryStatuses: {
		leaderboard: DEFAULT_QUERY_STATUS,
	},
	selectedTimeframe: '1M',
	leaderboard: {
		v2: DEFAULT_LEADERBOARD_DATA,
		v3: DEFAULT_LEADERBOARD_DATA,
	},
	leaderboardSearchTerm: '',
}

const statsSlice = createSlice({
	name: 'stats',
	initialState: STATS_INITIAL_STATE,
	reducers: {
		setSelectedTimeframe: (state, action: PayloadAction<StatsTimeframe>) => {
			state.selectedTimeframe = action.payload
		},
		setLeaderboardSearchTerm: (state, action: PayloadAction<string>) => {
			state.leaderboardSearchTerm = action.payload
		},
		setLeaderboardData: (
			state,
			{ payload }: PayloadAction<{ provider: PerpsProvider; leaderboard: Leaderboard }>
		) => {
			const { leaderboard, provider } = payload
			switch (provider) {
				case PerpsProvider.SNX_V2_OP:
				case PerpsProvider.PERENNIAL_V2_ARB:
					state.leaderboard.v2 = leaderboard
					break
				case PerpsProvider.SNX_V3_BASE:
				case PerpsProvider.SNX_V3_ARB:
					state.leaderboard.v3 = leaderboard
					break
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchLeaderboard.pending, (state) => {
			state.queryStatuses.leaderboard = LOADING_STATUS
		})
		builder.addCase(fetchLeaderboard.rejected, (state) => {
			state.queryStatuses.leaderboard = {
				status: FetchStatus.Error,
				error: 'Failed to fetch leaderboard',
			}
		})
		builder.addCase(fetchLeaderboard.fulfilled, (state) => {
			state.queryStatuses.leaderboard = SUCCESS_STATUS
		})
	},
})

export const { setSelectedTimeframe, setLeaderboardSearchTerm, setLeaderboardData } =
	statsSlice.actions

export default statsSlice.reducer
