import type KwentaSDK from '@kwenta/sdk'
import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from 'state/types'

import { fetchBlogPosts, fetchFuturesStats } from './actions'
import type { BlogPost } from './types'

type HomeState = {
	marketsQueryStatus: FetchStatus
	futuresStatsQueryStatus: FetchStatus
	futuresStats: Awaited<ReturnType<KwentaSDK['stats']['getFuturesStats']>>
	blogPostsQueryStatus: FetchStatus
	blogPosts: BlogPost[]
}

export const HOME_INITIAL_STATE: HomeState = {
	marketsQueryStatus: FetchStatus.Idle,
	futuresStatsQueryStatus: FetchStatus.Idle,
	futuresStats: [],
	blogPosts: [],
	blogPostsQueryStatus: FetchStatus.Idle,
}

export const homeSlice = createSlice({
	name: 'home',
	initialState: HOME_INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchFuturesStats.pending, (state) => {
			state.futuresStatsQueryStatus = FetchStatus.Loading
		})
		builder.addCase(fetchFuturesStats.fulfilled, (state, action) => {
			state.futuresStatsQueryStatus = FetchStatus.Success
			state.futuresStats = action.payload
		})
		builder.addCase(fetchFuturesStats.rejected, (state) => {
			state.futuresStatsQueryStatus = FetchStatus.Error
		})
		builder.addCase(fetchBlogPosts.pending, (state) => {
			state.blogPostsQueryStatus = FetchStatus.Loading
		})
		builder.addCase(fetchBlogPosts.fulfilled, (state, action) => {
			state.blogPostsQueryStatus = FetchStatus.Success
			state.blogPosts = action.payload
		})
		builder.addCase(fetchBlogPosts.rejected, (state) => {
			state.blogPostsQueryStatus = FetchStatus.Error
		})
	},
})

export default homeSlice.reducer
