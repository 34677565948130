import Image from 'next/image'

import { PerpsProvider } from '@kwenta/sdk/types'
import ArbitrumIcon from 'assets/svg/providers/arbitrum.svg'
import BaseIcon from 'assets/svg/providers/base.svg'
import OptimismIcon from 'assets/svg/providers/optimism.svg'
import { SYNTH_ICONS } from 'utils/icons'

export const MIN_DEPOSIT_AMOUNT = 0.00001

export enum DepositTab {
	CRYPTO = 'crypto',
	FIAT = 'fiat',
}

export enum WithdrawTab {
	ONCHAIN = 'onchain-withdrawal',
	INTERNAL = 'internal-transfer',
}

export enum AccountTab {
	ACCOUNT = 'account',
	ASSET = 'asset',
}

export enum DrawerTab {
	WALLET = 'wallet',
	DEPOSIT = 'deposit',
	WITHDRAW = 'withdraw',
	CONFIRM = 'confirm',
}

export const coinOptions = [
	{
		value: 'USDC',
		label: 'USDC',
		icon: <Image src={SYNTH_ICONS.sUSDC} alt="USDC" width={20} height={20} />,
		providers: [PerpsProvider.PERENNIAL_V2_ARB, PerpsProvider.SNX_V3_BASE],
	},
	{
		value: 'sUSD',
		label: 'sUSD',
		icon: <Image src={SYNTH_ICONS.sUSD} alt="USD" width={20} height={20} />,
		providers: [PerpsProvider.SNX_V2_OP],
	},
]

export const chainOptions = [
	{
		value: 'Base',
		label: 'Base',
		icon: <BaseIcon width={20} height={20} />,
		providers: [PerpsProvider.SNX_V3_BASE],
	},
	{
		value: 'Optimism',
		label: 'Optimism',
		icon: <OptimismIcon width={20} height={20} />,
		providers: [PerpsProvider.SNX_V2_OP],
	},
	{
		value: 'Arbitrum',
		label: 'Arbitrum',
		icon: <ArbitrumIcon width={20} height={20} />,
		providers: [PerpsProvider.PERENNIAL_V2_ARB],
	},
]

export const SOCIAL_LOGIN_ENABLED = process.env.NEXT_PUBLIC_SOCIAL_LOGIN_ENABLED === 'true'
