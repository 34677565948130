import { Language } from 'translations/constants'

// app defaults
export const DEFAULT_LANGUAGE: Language = Language.EN

// network defaults
export const DEFAULT_NETWORK_ID = 10

export const DEFAULT_GAS_BUFFER = 5000
export const DEFAULT_GAS_LIMIT = 500000
export const DEFAULT_CROSSMARGIN_GAS_BUFFER_PCT = 5

// ui defaults
export const DEFAULT_SEARCH_DEBOUNCE_MS = 300
export const DEFAULT_REQUEST_REFRESH_INTERVAL = 30000 // 30s
export const DEFAULT_CRYPTO_DECIMALS = 4
export const DEFAULT_FIAT_DECIMALS = 2
export const DEFAULT_NUMBER_DECIMALS = 2
export const DEFAULT_PERCENT_DECIMALS = 2
export const DEFAULT_TOKEN_DECIMALS = 18

// for Trading History
export const DEFAULT_NUMBER_OF_TRADES: number = 32
export const MAX_TIMESTAMP: number = 8640000000000000
export const MAX_TOTAL_TRADES: number = 9999
// for Fee History
export const DEFAULT_NUMBER_OF_FUTURES_FEE: number = 9999

// for mobile leaderboard
export const DEFAULT_LEADERBOARD_ROWS = 20

// for perps v2
export const DEFAULT_DELAYED_EXECUTION_BUFFER = 40
export const DEFAULT_DELAYED_CANCEL_BUFFER = 10

// for trade notification
export const DEFAULT_NOTIFICATION_TIMEOUT = 90

export const ORDERS_WARNING_DISABLED = true

export const DEFAULT_LEVERAGE = '1'

export const MARGIN_ENGINE_ENABLED = true
export const V3_CONDITIONAL_ORDERS_ENABLED = true

export const SWAP_QUOTE_BUFFER = 0.2

// One click trading
export const EST_TRADE_TX_COST_ETH = 0.0001
export const IS_ONE_CLICK_TRADING_ENABLED = true
export const ONE_CLICK_TRADING_DEFAULT_DEPOSIT_TOKEN = 'ETH'
export const SPONSORED_DEPOSIT_IN_WEI = BigInt(50_000)
